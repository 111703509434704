import { useEffect, createContext, useContext, useMemo, useState} from 'react'
import { MIN_STEPS, MAX_STEPS } from 'shared/listing_helpers';

import ListingContext from './ListingContext';
import { stepHasErrors} from '../components/ListingWizard/helpers/hasErrors';

const StepContext = createContext();
export const Provider = ({value, children}) => {
  value = _.clamp(value, MIN_STEPS, MAX_STEPS);
  const [step, setStep] = useState(value);
  const {validateStep, getListing, getErrors, waitForPendingOperations} = useContext(ListingContext);

  const gotoStep = (nextStep, whenInvalid=true) => waitForPendingOperations().then(async () => {
    nextStep = _.clamp(nextStep, MIN_STEPS, MAX_STEPS);

    if (nextStep > step) {
      await validateStep(step, nextStep);
      if (nextStep === 5 && !getListing().has_behavior_profile) {
        nextStep = 6;
        await validateStep(nextStep);
      }
      if (stepHasErrors(getErrors(), step) && !whenInvalid) {
        nextStep = step;
      }
    } else {
      if (nextStep === 5 && !getListing().has_behavior_profile) {
        nextStep = step;
      }
    }

    setStep(nextStep);
  });

  const api = useMemo(() => ({
    step, gotoStep,
    nextStep(whenInvalid=true) { gotoStep(step + 1, whenInvalid); },
    prevStep() {
      if (step > MIN_STEPS) {
        gotoStep(step - 1)
      }
    },
  }), [step]);

  useEffect(() => setStep(_.clamp(value, MIN_STEPS, MAX_STEPS)), [value]);

  return <StepContext.Provider value={api}>
    {children}
  </StepContext.Provider>
}

export default StepContext;
