import React, {PropTypes} from 'react';
import classnames from 'classnames';

export default class ListingMarker extends React.Component {
  render() {
    const {location, listingsAvailable, active, key, setActive} = this.props;
    const {name, address, city, state, zipcode, id} = location;
    return (
      <div className={classnames("marker", {active})} onClick={(e) => setActive(id)}>
        <div className="marker-overlay">
          <div className="tooltip-wrapper">
            <div title={name} className="listing-tooltip col-md-auto">
              <h4 className="tooltip-name">{name}</h4>
              {address}
              <br/>
              {city}, {state} {zipcode}
              <div className="available">
                <h4>{listingsAvailable}</h4>
                Horses Available
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
