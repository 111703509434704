import Donut from '../Graphs/Donut';
import AverageDonut from '../Graphs/AverageDonut';
import Line from '../Graphs/Line';
import Historical from '../Graphs/Historical';
import Bar from '../Graphs/Bar';
import AgeBar from '../Graphs/AgeBar';
import TopTen from '../Graphs/TopTen';

const GRAPHS = {
  Donut: Donut,
  AverageDonut: AverageDonut,
  Line: Line,
  Historical: Historical,
  Bar: Bar,
  AgeBar: AgeBar,
  TopTen: TopTen
};

export default class AnalyticsCard extends React.Component {
  state = {
    showHelp: false,
  }

  toggleHelp = () => this.setState(({showHelp}) => ({showHelp: !showHelp}))

  render() {
    const {title, data, dateRange, helpText={}, graphType, className, activeOnly} = this.props;
    const {showHelp} = this.state;
    const Graph = GRAPHS[graphType];

    return (
      <div className={`stat-card ${className}`}>
        <div className={classnames("stat-content", {hidden: showHelp})}>
          {helpText[title] && (
            <div className="help-toggle" onClick={this.toggleHelp}>?</div>
          )}
          <h3 className="card-title">{title}</h3>
          {_.isEmpty(data) ? (
            <div className="no-data">No data available</div>
          ) : (
            <Graph data={data} dateRange={dateRange}/>
          )}
        </div>
        <div className={classnames("stat-help", {hidden: !showHelp})}>
          <div className="help-toggle" onClick={this.toggleHelp}>?</div>
          <h3 className="card-title">{title}</h3>
          <p>{_.get(helpText, [title, (activeOnly ? 'active_help' : 'help')])}</p>
        </div>
      </div>
    );
  };
};
