import { useState, useCallback } from 'react';
import Dropzone from 'react-dropzone';
import { importCSV } from 'components/shared/listing_helpers';
import { parseCSVPreview, guessMappings } from 'components/shared/csv_helpers';
import CSVMappingTable, { IGNORE_VALUE } from 'components/CSVForm/CSVMappingTable';

const INSTRUCTION_CONTENT = {
  template: (
    <>
      <strong className="d-block pt-4">Instructions</strong>
      <ul>
        <li>Categories with a * are required. If you don't include them in the spreadsheet, you'll have to manually add them later in each listing.</li>
        <li>Name is the horse's name and can be as long or as short as needed.</li>
        <li>Height is in hands.</li>
        <li>We list nearly all breed options, with an option to click "other" if one isn't listed.</li>
        <li>If your horse has multiple breeds, you can seperate each with a comma.</li>
        <li>Gender options are: Gelding, Mare, Stallion, Colt, and Filly. If you use Male/Female, you will have to edit it in the listing.</li>
        <li>Birth Year is the horse's year of birth.</li>
        <li>Adoption fee cannot be set to 0 at this time. If the adoption is sponsored feel free to write that in the description.</li>
        <li>ID gives you the option to identify horses by unique numbers, which can help you update the horse listing via CSV later if you'd like to. Examples of this would be the Chip ID number or the intake number.</li>
        <li>Images that are already hosted on the web can be included here in a comma-seperate list of their URLs and will import directly into the listing.</li>
      </ul>
    </>
  ),
  update: (
    <>
      <p>If you want to update a horse (or multiple horses) that have already been listed, you can upload a CSV with the updated information and that listing will update automatically. Here’s how:</p>
      <ul>
        <li>Make sure the horses you posted previously have a unique identifier in the “Id” section. This could be a chip ID number or intake number. This is how the CSV uploader identifies which horse needs to be updated.</li>
        <li>Update the information in your intake system and download the CSV (or edit directly in the CSV). For example, If you use Airtable to track your horses, and you wrote a new description for a horse that’s already been posted, you would edit the description in airtable, download the CSV, and upload it to My Right Horse.</li>
        <li>As long as the ID in the CSV and the listing for the horse match, all updated information will be edited and your listing will update automatically.</li>
      </ul>
    </>
  ),
  upload: (
    <>
      <p>There are two options for uploading images:</p>
      <ul>
        <li>Once your CSV is done uploading, you will see list of the horses you've just imported. To the right of the horse you'd like to edit, you can click “add cover photo” and upload up to 8 images for the listing.</li>
        <li>If you have images publically available on the web, you can copy and paste their links as a comma-seperate list into your spreadsheet under “images”. When you upload your csv, they will automatically be added to the listing.</li>
      </ul>
    </>
    )
};

export default function CSVForm({
  csvUpload, downloadPath, previousMapping, requiredHeaders, supportedHeaders
}) {
  const [error, setError] = useState(csvUpload.errors);
  const [file, setFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const [mapping, setMapping] = useState(previousMapping ?? {});
  const [saving, setSaving] = useState(false);
  const [selected, setSelected] = useState(false);

  const onDrop = useCallback(async (file) => {
    file = _.first(file);
    setFile(file);
    if (file) {
      const parsed = await parseCSVPreview(file);
      setPreview(parsed);
      setMapping(guessMappings(supportedHeaders, parsed.meta.fields, mapping));
    }
  });

  const save = () => {
    setSaving(true);
    importCSV(file, mapping).then(({data}) => {
      if (data.status === 'success') window.location.href = data.redirect_to;
      else setError(data.error);
      setSaving(false);
    }).catch((err) => {
      setSaving(false);
    });
  };

  const toggleInstructions = (selection) => {
    if (selected == selection) {
      setSelected(null);
    } else {
      setSelected(selection);
    }
  };

  const hasAllRequired = _.every(requiredHeaders, (header) => mapping[header]);
  const mappingValues = _.compact(_.without(_.values(mapping), IGNORE_VALUE));
  const noDuplicates = mappingValues.length === _.uniq(mappingValues).length;
  const isValid = hasAllRequired && noDuplicates;

  return (
    <div className="csv-upload-page">
      <div className="py-4">
        {error && (
          <div className="error-message">{error}</div>
        )}
        <ol>
          <li>
            <strong>Upload your CSV file</strong>
            Choose or drag your file to add horses for adoption.
          </li>
          <li>
            <strong>Match the fields</strong>
            Connect your information with our categories. For example, "Horse name" would go with "Name".

          </li>
          <li>
            <strong>Edit</strong>
            Add photos and edit your listings. If there are categories required that aren't
              recorded in your CSV, consider adding a column to collect that information to make adding
              horses quicker and easier.
          </li>
          <li>
            <strong>Publish</strong>
            Listings that meet all requirements will show a "publish" button. Don't
              worry - if you can't publish them all right away, they'll be saved in your drafts.
          </li>
        </ol>
      <div className="button-row">
        <button className="btn btn-outline-secondary" onClick={() => toggleInstructions('template')}>CSV Template</button>
        <button className="btn btn-outline-secondary" onClick={() => toggleInstructions('update')}>Updating Existing Listings</button>
        <button className="btn btn-outline-secondary" onClick={() => toggleInstructions('upload')}>Uploading Images</button>
      </div>
      {selected == "template" && (
        <div className="selected-instructions">
          <a href={downloadPath}>Click to download CSV template</a>
          {INSTRUCTION_CONTENT["template"]}
        </div>
      )}
      {selected == "update" && <div className="selected-instructions">{INSTRUCTION_CONTENT["update"]}</div>}
      {selected == "upload" && <div className="selected-instructions">{INSTRUCTION_CONTENT["upload"]}</div>}
        <Dropzone className="dropzone d-flex flex-wrap flex-column"
          onDrop={onDrop}
          accept="text/csv"
          multiple={false}
        >
          <span className="label">
            <button type="button" className="btn btn-primary mr-2 px-4 select-file" disabled={saving}>
              Select File
            </button>
            or drag & drop files here
          </span>
          <div className={classnames('pt-4', {'font-weight-bolder': file})}>
            {file ? <><i className="icon-csv-documenticon"/> {file.name}</> : "No file selected"}
          </div>
        </Dropzone>
      </div>
      <div>
        {file && (
          <div>
            <div className="listing-title">
              <h3>Preview</h3>
            </div>
            <CSVMappingTable
              preview={preview}
              mapping={mapping}
              setMapping={setMapping}
              supportedHeaders={supportedHeaders}
              requiredHeaders={requiredHeaders}/>
            <div className="py-4 text-center">
              <button className="btn btn-primary btn-lg" disabled={!file || !isValid || saving} onClick={save}>
                Import
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
