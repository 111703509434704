import Switch from "react-switch";
import {updateEntity} from './libs/requestsManager';

export default class DataPrivacyToggle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {data_public: props.organization.data_public};
  }

  handleToggle = () => {
    const {id} = this.props.organization;
    const {data_public} = this.state;

    return updateEntity(`/data_privacy`, {id, data_public: !data_public})
      .then((response) => {
        this.setState({data_public: !data_public});
        return response;
      })
      .catch((error) => {
        this.setState({error: error.response.data.error});
        return error;
      });
  }

  render() {
    const {id} = this.props.organization;
    const {data_public} = this.state;

    return(
      <div className="col-2">
        <div className="d-inline-block text-center">

          <p className={classnames("react-switch-container", {disabled: !data_public})}>
            {data_public ? "Active" : "Disabled"}
          </p>
          <Switch
            onChange={this.handleToggle}
            checked={data_public}
            className={classnames("react-switch", "public-data-toggle", {disabled: !data_public})}
            height={20}
            width={52}
            onHandleColor="#363636"
            offHandleColor="#FF6300"
            onColor="var(--light-gray)"
            offColor="var(--light-gray)"
            uncheckedIcon={false}
            checkedIcon={false}
          />
        </div>
      </div>
    )
  }
}
