import { useContext } from 'react';

import ListingContext from '@/contexts/ListingContext';

import InfoStep from './InfoStep';

export default function InfoStepFive() {
  const {listing: {has_behavior_profile}={}} = useContext(ListingContext);
  const skip = !has_behavior_profile;
  return (
    <InfoStep
      currentStep="5"
      skip={skip}
      title="Basic Behavior Profile"
    >
      <div className="text-block">
        <p>
          { "Your horse has completed the Basic Behavior Profile. Make sure to select the correct result and provide the evaluator's name and completion date." }
        </p>
      </div>
      <div className="text-block">
        <p>
          { "This helps the adopter understand where a horse is at in its training." }
        </p>
      </div>
    </InfoStep>
  );
}

