import ReversibleButton from './DashboardListingCard/components/buttons/ReversibleButton';
import EditButton from './DashboardListingCard/components/buttons/EditButton';
import ChangeStatusButton from './DashboardListingCard/components/buttons/ChangeStatusButton';
import AdoptedButton from './DashboardListingCard/components/buttons/AdoptedButton';
import { submitListingAction, setInitialStatus } from './shared/listing_helpers';

const ADMIN_HEADER = {
  published: 'This listing is published and live.',
  draft: 'This listing is still a draft.',
  adopted: 'Congratulations! This horse has been adopted.',
  archived: 'This listing has been archived.',
  stalled: 'This listing has been stalled.'
}

export default class StatusButtons extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      listing: props.listing
    };
  }

  submitListingAction = (action, status_reason) => {
    submitListingAction(this.props.listing, action, status_reason, (error, response) => {
      if (!_.isEmpty(_.get(response.body, 'errors', {}))) {
        this.setState({error: error.response.body.error});
      } else {
        this.setState(({listing}) => ({
          error: null,
          listing: {
            ...listing,
            ..._.pick(response.body, [
              'status', 'days_remaining', 'percent_remaining'
            ])
          }
        }));
      }
    });
  }

  render() {
    const {listing} = this.state;
    const {days_remaining, status, active_transfer} = this.props.listing;
    const {EXPIRING_SOON_DAYS, STATUS_REASONS} = this.props;
    const initialStatus = setInitialStatus(status, days_remaining, EXPIRING_SOON_DAYS);
    const moved = listing.status !== status;

    return (
      <div>
        <h4>{ADMIN_HEADER[listing.status]}</h4>
        <div className="status-buttons-container">
          <ReversibleButton
            activeStatus={listing.status}
            newStatus="draft"
            action={this.submitListingAction}
            actionLabel="Unpublish"
            moved={moved}/>
          <AdoptedButton
            activeTransfer={listing.active_transfer}
            listing={listing}
            action={this.submitListingAction}
            moved={moved}
            statusReasons={STATUS_REASONS}/>
          <EditButton listingId={listing.id}/>
          <ChangeStatusButton
            listing={listing}
            action={this.submitListingAction}
            initialStatus={initialStatus}
            moved={moved}
            statusReasons={STATUS_REASONS}/>
        </div>
        {active_transfer && (
          <p className="transfer-status">
            {
              active_transfer.status == 'initiated' ? 'Pending Transfer to' : 'Transfer Accepted by'
            }: <strong>{active_transfer.organization_name}</strong>
          </p>
        )}
      </div>
    );
  }
}
