const preventDefault = (fn) => (e) => {
  e.preventDefault();
  if (_.isFunction(fn)) return fn();
}
const REASON_OPTION_LABELS = {
  'Deleted': 'Delete'
};
const optionLabelFor = (reason) => _.get(REASON_OPTION_LABELS, reason, reason);

const StatusReasonOption = ({action, status, reason}) => (
  <a href="#"
    className="dropdown-item"
    onClick={preventDefault(() => action(status, reason))}
  >
    {optionLabelFor(reason)}
  </a>
);

export default StatusReasonOption;
