import { ResponsiveContainer, PieChart, Pie, Cell, Label, Sector } from 'recharts';
import { MultiLineLabel } from '../components/MultiLineLabel';

export default class Donut extends React.Component {
  state = {activeIndex: null}

  highlight = (data, index) => !data.hidden && this.setState({activeIndex: index})
  unhighlight = (data, index) => !data.hidden && this.setState(
    ({activeIndex}) => ({activeIndex: activeIndex == index ? null : activeIndex})
  )

  render() {
    const {data} = this.props;
    const {activeIndex} = this.state;
    const total = _.sumBy(_.reject(data, {hidden: true}), 'value');

    return <React.Fragment>
      <ResponsiveContainer height={140}>
        <PieChart>
          <circle cx="50%" cy="50%" r="60" fill="none" stroke="#bbb" />
          <Pie data={data}
            dataKey="value"
            activeIndex={activeIndex}
            activeShape={
              ({innerRadius, ...props}) => <Sector {...props} innerRadius={innerRadius*0.8}/>
            }
            innerRadius="80%"
            outerRadius="100%"
            startAngle={90}
            endAngle={360+90}
            onMouseEnter={this.highlight}
            onMouseLeave={this.unhighlight}
          >
            {data.map(({name, color, hidden}) => (
              <Cell key={name} fill={hidden ? 'transparent' : color} strokeWidth={0}/>
            ))}
            <Label position="center" className="pie-label" dy={4} content={<MultiLineLabel/>}>
              {activeIndex != null ? data[activeIndex].name : 'Total'}
            </Label>
            <Label position="center" className="pie-value" dy={12} fill="">
              {activeIndex != null ? data[activeIndex].value : total}
            </Label>
          </Pie>
        </PieChart>
      </ResponsiveContainer>
      <table className="legend">
        <tbody>
          {data.map(({name, labelValue, color, hidden}, i) => !hidden && (
            <tr key={name} onMouseEnter={() => this.highlight({}, i)} onMouseLeave={() => this.unhighlight({}, i)}>
              <td><span className="legend-swatch" style={{background: color}}/>{name}</td>
              <td>{labelValue}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </React.Fragment>;
  }
}
