import { useState, useEffect, useContext } from "react";
import ListingContext, { Provider } from "@/contexts/ListingContext";
import MediaUpload from "./ListingWizard/components/MediaUpload";
import SVGIcons from "./CSVUpload/components/SVGIcons";
import InfoBlock from "./CSVUpload/components/InfoBlock";
import ListingRow from "./CSVUpload/components/ListingRow";
import { createHelpRequest } from './shared/listing_helpers';

const MediaUploadWithContext = () => {
  const {listing} = useContext(ListingContext);

  return <MediaUpload
    label="Upload images."
    required={true}
    published={listing.status == 'published'}
    images={listing.images}
    modalImages={listing.large_images}
    cover_image_id={listing.cover_image_id}/>
}

const trRowNumber = (func) => ({row_number, errors}) => func({row_number: row_number === 'file' ? null : row_number, errors});

const uploadSupportEnabled = !!document.querySelector("meta[name=upload-support-enabled]");

export default function CSVUpload(props) {
  const [upload, setUpload] = useState(props.upload);
  const [selectedListing, setSelectedListing] = useState();
  const [youWillBeContacted, setYouWillBeContacted] = useState();

  const {
    carrierwave_file_identifier, total_records="?", records_inserted=0,
    listing_update_problems, records_processed, listings_json, listings=listings_json
  } = upload;

  const has_file_errors = _.some(listing_update_problems, {row_number: 'file'});
  const records_errored = has_file_errors ? 0 : upload.records_errored;
  const isDone = (total_records || has_file_errors) && records_processed >= total_records;
  const hasExternalId = _.some(listings, 'external_id');

  useEffect(() => {
    if (!isDone) getUpload();
  }, [upload, isDone])

  const getUpload = async () => {
    await new Promise((r) => setTimeout(r, 2000));
    $.get(window.location, setUpload, 'json');
  };

  const updateSelectedListing = (listing) => {
    if (listing.id === selectedListing?.id) {
      setSelectedListing(listing);
      setUpload(({listings_json, listings=listings_json, ...upload}) => {
        return {
          ...upload,
          listings: _.map(listings, (uploadListing) => (
            uploadListing.id === listing.id ? listing : uploadListing
          ))
        };
      });
    }
  }

  const addCoverPhoto = (id=null) => (_e) => setSelectedListing(_.find(listings, {id}));

  const getHelp = async () => {
    await createHelpRequest(upload.id);
    setYouWillBeContacted(true);
  }

  return <>
    <SVGIcons/>
    <div>
      {isDone ? (
        <h3>Your import of {carrierwave_file_identifier} is complete!</h3>
      ) : (
        <h3>Your file, {carrierwave_file_identifier}, is currently importing.</h3>
      )}
      <div className="info-blocks">
        <InfoBlock icon="report" number={total_records} words="records found"/>
        <InfoBlock icon="checkmark" number={records_inserted} words="successfully imported"/>
        <InfoBlock icon="x" number={records_errored} words="failed import"/>
      </div>

      {!_.isEmpty(listing_update_problems) && <>
        <div className="import-errors">
          <div className="import-error-title">Errors:</div>
          <ul>
            {_.map(listing_update_problems, trRowNumber(({row_number, errors}) => (
              <li key={row_number} className="import-error-row">
                {!!row_number && <strong>Row {row_number}: </strong>}
                {errors}
              </li>
            )))}
          </ul>
        </div>
        {uploadSupportEnabled && (
          <div style={{textAlign: "center"}}>
            {youWillBeContacted ? (
              <p className="alert alert-success">
                Someone from My Right Horse will be in touch about this.
              </p>
            ) : (
              <button className="btn btn-outline-primary mb-3" onClick={getHelp}>
                Ask for help
              </button>
            )}
          </div>
        )}
      </>}
    </div>
    <div className="row csv-listing-manager">
      { isDone && total_records === 0 ? (
        <div style={{flexGrow: 1, margin: '2em 0'}}>
          <h4 style={{textAlign: 'center'}}>Processing Halted</h4>
        </div>
      ) : (
        <table className={classnames("table table-hover col-sm", {"edit-mode": selectedListing})}>
          <thead>
            <tr>
              <th>Name</th>
              {!selectedListing && <>
                {hasExternalId && <th>CSV Id</th>}
                <th>Actions</th>
              </>}
            </tr>
          </thead>
          <tbody>
            {_.map(listings, (listing) => (
              <Provider key={listing.id} value={listing}>
                <ListingRow selectedId={selectedListing?.id} addCoverPhoto={addCoverPhoto} hasExternalId={hasExternalId}/>
              </Provider>
            ))}
          </tbody>
        </table>
      )}
      {selectedListing && (
        <Provider value={selectedListing} updater={updateSelectedListing}>
          <div className="col-sm-8 bg-light py-2">
            <button type="button" className="close" aria-label="Close" onClick={addCoverPhoto()}>
              <span aria-hidden="true">&times;</span>
            </button>
            <h4>Editing {selectedListing.name}</h4>
            <MediaUploadWithContext/>
          </div>
        </Provider>
      )}
    </div>
  </>
}
