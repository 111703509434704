import superagent from 'superagent';
import moment from 'moment';
import classnames from 'classnames';
import Switch from "react-switch";
const csrfToken = _.get(document.querySelectorAll('meta[name="csrf-token"]'), '[0].content');
const headers = {
  'Accept': 'application/json',
  'Content-Type': 'application/json',
  'X-CSRF-Token': csrfToken
}

export default class KeyRow extends React.Component {
  constructor(props) {
    super(props);
    const {label, disabled_at} = props;
    this.state = {
      editing: false,
      error: null,
      deleted: false,
      apiKey: {
        disabled_at,
        label
      }
    }

    this.saveLabel = _.debounce(this.saveLabel.bind(this), 500, {'leading': true});
  }

  allowSave = true;

  enableEditMode = () => {
    this.setState({editing: true});
  }

  localUpdate = ({target: {value}}) => {
    this.setState(({apiKey}) => ({apiKey: {...apiKey, label: value}}));
  };

  handlePress = (e) => {
    if (e.charCode === 13) this.saveLabel();
  }

  handleToggle = (checked) => {
    const disabled_at = checked ? null : moment();
    this.save({...this.state.apiKey, disabled_at}).then((response) => {
      if (!_.get(response.body, 'error')) {
        this.setState(({apiKey}) => ({apiKey: {...apiKey, disabled_at: response.body.disabled_at}}));
      }
    });
  }

  saveLabel = () => this.save({label: this.state.apiKey.label})

  save = (apiKey) => {
    const {id} = this.props;
    if (this.allowSave) {
      return superagent.patch(`/api_keys/${id}.json`)
                .set(headers).responseType('json')
                .send({api_key: apiKey})
                .then((response) => {
                  this.setState({error: null, editing: false});
                  return response;
                },
                (error) => {
                  this.setState({error: error.response.body.error, editing: true});
                  return error.response;
                });
    }
  }

  delete = (id) => () => {
    this.allowSave = false;
    superagent.delete(`/api_keys/${id}.json`)
              .set(headers).responseType('json')
              .end((error) => {
                if (error) {
                  this.setState({error: "There was an error deleting"});
                }
                else {
                  this.setState({deleted: true});
                }
              });
  }

  render() {
    const {created_at, auth_token, id} = this.props;
    const {editing, error, deleted, apiKey} = this.state;
    const {disabled_at, label} = apiKey;

    return (
      !deleted &&
        (
          <tr scope="row">
            <td>
              <p className={classnames("api-key-date", {"mb-0": !disabled_at})}>
                { moment(created_at).format('MM/DD/YY h:mm a') }
              </p>
            <small className="d-none d-xl-block">
              {disabled_at && `Disabled on ${moment(disabled_at).format('MM/DD/YY')}`}
            </small>
            </td>
            <td className={classnames({error})}>
              {error && <span className="error-message">{error}</span>}
              { editing ? (
                <input
                  type="text"
                  value={label}
                  className="form-control"
                  onChange={this.localUpdate}
                  onBlur={this.saveLabel}
                  onKeyPress={this.handlePress}
                />
                )
                : label
              }
            </td>
            <td className="auth-token">
              <span className={disabled_at && "disabled"}>{ auth_token }</span>
            </td>
            <td className="d-flex align-items-center">
              <div className="d-inline-block text-center">
                <p
                  className={classnames("react-switch-container", {disabled: disabled_at})}>
                  {!disabled_at ? "Active" : "Disabled"}
                </p>
                  <Switch
                    onChange={this.handleToggle}
                    checked={!disabled_at}
                    className={classnames("react-switch", {disabled: disabled_at})}
                    height={20}
                    width={52}
                    onHandleColor="#363636"
                    offHandleColor="#FF6300"
                    onColor="var(--light-gray)"
                    offColor="var(--light-gray)"
                    uncheckedIcon={false}
                    checkedIcon={false}
                  />
                </div>
              { editing ?
                <i className="icon-saveandcircle ml-1" onMouseDown={this.saveLabel}/>
              : <i className='icon-editandcircle ml-1' onMouseDown={this.enableEditMode}/>
              }
              <i className="icon-trashandcircle" onMouseDown={this.delete(id)}/>
            </td>
          </tr>
        )
    )
  }
}
