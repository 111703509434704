import { Editor, Label } from "@tinymce/tinymce-react";

const CharacterMessage = ({characterCount, min, className}) => {
  if (characterCount >= min) return null;

  const charsRemaining = Math.max(0, min - characterCount);
  return (
    <small className={className}>
      {charsRemaining} characters short of {min} character requirement
    </small>
  );
};

export default class DescriptionEditor extends React.Component {
  state = {value: ""}

  tinymce = React.createRef()

  localUpdate = (value) => this.setState({value})

  render() {
    const {
      label, required, min=0, placeholder, updateField, fieldName,
      error=[], has_errors
    } = this.props;
    const {value} = this.state;
    const requiredField = required ? '*' : null;
    const characterCount = _.invoke(this.tinymce, 'current.plugins.wordcount.body.getCharacterCount');

    return (
      <div className={classnames("form-group", {error: error.length})}>
        <label>{label} {requiredField}</label>
        <div className={classnames('error-message', {has_errors})}>
          { _.capitalize(error.join(', ')).replace(/_/g, ' ') }
        </div>
        <Editor
          apiKey={process.env.TINYMCE_API_KEY || "84wxezk7nekn1z0q2dpdpme2q5xmjebvtyfifdbvrak99dcb"}
          onInit={(evt, editor) => {
            this.tinymce.current = editor;
            this.localUpdate(editor.getContent());
          }}
          initialValue={this.props.value}
          init={{
            placeholder: 'Describe your horse',
            height: 300,
            menubar: false,
            plugins: [
              'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
              'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
              'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
            ],
            toolbar: 'undo redo | blocks | ' +
              'bold italic forecolor | alignleft aligncenter ' +
              'alignright alignjustify | bullist numlist outdent indent | ' +
              'removeformat | help',
            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
          }}
          onEditorChange={this.localUpdate}
          onBlur={() => updateField(this.state.value)}
        />
        <CharacterMessage characterCount={characterCount} min={min}/>
      </div>
    );
  }
}
