import { preventDefault } from 'shared/events';

export default class BooleanSelect extends React.Component {
  constructor(props) {
    super(props);
    const {complete} = props;
    this.state = {complete};
  }

  toggle = () => {
    const {complete} = this.state;
    const {fieldName, updateField} = this.props;
    this.setState({complete: !complete});
    updateField(fieldName, !complete);
  }

  render() {
    const {complete} = this.state;
    const {
      label,
      fieldName,
      trueLabel='Complete',
      falseLabel='Incomplete'
    } = this.props;

    return (
      <div className="form-group">
        <label>
          {label}
        </label>
        <div className="row">
          <div className="col-6">
            <div className="form-control border-0">
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id={`${fieldName}_${trueLabel}`}
                  readOnly={true}
                  checked={complete}
                />
                <label
                  className="custom-control-label"
                  htmlFor={`${fieldName}_${trueLabel}`}
                  onClick={preventDefault(this.toggle)}
                >
                  {trueLabel}
                </label>
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="form-control border-0">
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id={`${fieldName}_${falseLabel}`}
                  readOnly={true}
                  checked={!complete}
                />
                <label
                  className="custom-control-label"
                  htmlFor={`${fieldName}_${falseLabel}`}
                  onClick={preventDefault(this.toggle)}
                >
                  {falseLabel}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
