import VideoLinks from '../VideoLinks';
import MediaUpload from '../MediaUpload';

export default class StepThree extends React.Component {

  render() {
    const {videos, images, large_images, cover_image_id, status, errors={}} = this.props;

    const updateField = (e) => {
      this.props.updateField(e.target.name, e.target.value);
    }

    return (
      <div>
        <VideoLinks
          fieldName="videos"
          value={videos}
          updateField={updateField}
          error={errors['videos']}
        />
        <MediaUpload
          label="Upload images."
          required={true}
          published={status == 'published'}
          images={images}
          modalImages={large_images}
          cover_image_id={cover_image_id}
          error={errors['listing_media']}
        />
      </div>
    );
  }
}
