import { dateFormatter, dateLabel } from '../date-helpers';
import {
  ResponsiveContainer,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
  Label
} from 'recharts';

export default function({data, dateRange}) {
  const {values, dataKey, dataColor, period} = data;

  return <React.Fragment>
    <ResponsiveContainer height={180}>
      <BarChart data={values} margin={{top: 7, bottom: 30}}>
        <CartesianGrid vertical={false} horizontalPoints={[1, 30, 60, 90]}/>
        <XAxis
          dataKey="name"
          type="category"
          textAnchor="end"
          tickFormatter={dateFormatter(period)}
          tick={{angle: -45}}
          fontSize={10}
          interval={0}
          padding={{left:20, right: 15}}
        />
        <YAxis
          mirror
          allowDecimals={false}
          axisLine={false}
          tickLine={false}
          tickSize={0}
        />
        <Tooltip
          contentStyle={{padding: "5px 10px"}}
          labelFormatter={dateLabel(period, dateRange.to)}
          formatter={(value, name, props) => (
            <div>{value} {name}</div>
          )}
          cursor={false}
        />
        <Bar dataKey={dataKey} dot={false} fill={dataColor} barSize={10}/>
      </BarChart>
    </ResponsiveContainer>
    <dl className="graph-total">
      <dt>Total</dt>
      <dd>{_.sumBy(values, dataKey).toLocaleString()}</dd>
    </dl>
  </React.Fragment>;
}
