import InfoStep from './InfoStep';

export default function InfoStepOne() {
  return (
    <InfoStep currentStep="1" title="General Info" >
      <div className="text-block">
        <span>{ "Let's start with the basics." }</span>
      </div>

      <div className="text-block">
        <p>
          Adding extra details helps the right person find the right horse.
          <br/>
          <br/>
          { "If you need to change these details later, it's easy to come back and make edits." }
        </p>
      </div>
    </InfoStep>
  );
}
