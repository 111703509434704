export default function ReversibleButton({
    activeStatus, newStatus, action, actionLabel, moved, width="half", primaryClass
}) {
  const undoMode = activeStatus == newStatus;

  return (
    <div className={width == "half" ? "col-6" : "col-12"}>
      <button
        className={`btn btn-block ${primaryClass ? 'btn-outline-secondary' : 'btn-secondary'}`}
        type="button"
        onClick={() => action(undoMode ? "undo" : newStatus)}
        disabled={moved && !undoMode}
      >
        {undoMode ? "Undo" : actionLabel}
      </button>
    </div>
  );
}
