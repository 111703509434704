import moment from 'moment';

const DATE_FORMATS = {
  day: "ddd",
  week: "[Week] w",
  month: "MMM",
  quarter: "YYYY [Q]Q",
  year: "YYYY"
}

export const dateFormatter = (period) => (date) => moment(date).format(DATE_FORMATS[period]);

const endDateFor = (period, date) => {
  if (period == '2day') return moment(date).add(1, 'days');
  if (period == '4day') return moment(date).add(3, 'days');
  return moment(date).endOf(period);
}


export const dateLabel = (period, end) => (date) =>  {
  const startDate = moment(date).format("MMM D");
  const endDate = moment.min(endDateFor(period, date), moment(end));
  const labelPeriod = moment(date).isSame(endDate, "day") ? "day" : period;

  const onMonthBoundary = !moment(date).isSame(endDate, 'month');
  const endDateFormat = onMonthBoundary ? "MMM D" : "D";

  switch (labelPeriod) {
    case "day":
      return startDate;
    case "week":
    case "4day":
    case "2day":
      return `${startDate}-${endDate.format(endDateFormat)}`;
    case "quarter":
      return `${startDate}-${endDate.format("MMM D")}`;
    case "year":
      return `Year ${moment(date).format("YYYY")}`;
    default:
      return `${startDate}-${endDate.format("D")}`;
  }
};
