import StatusReasonOption from 'shared/StatusReasonOption';
import TransferListingOptions from 'shared/TransferListingOptions';
import React from 'react';
import classNames from 'classnames';

const ARCHIVED = "archived";
const STALLED = "stalled";

export default class ChangeStatusButton extends React.Component {
  state = {showDropdown: false}

  componentWillUnmount() {
    document.removeEventListener('click', this.hideDropdown);
  }

  showDropdown = (e) => {
    e.stopPropagation();
    this.setState({showDropdown: true});
    document.addEventListener('click', this.hideDropdown, false);
  }

  hideDropdown = () => {
    if (this.state.showDropdown) {
      this.setState({showDropdown: false});
      document.removeEventListener('click', this.hideDropdown);
    }
  }

  render() {
    const {
      listing,
      action,
      initialStatus,
      moved,
      statusReasons,
      primaryClass,
      dropupMenu
    } = this.props;
    const {showDropdown} = this.state;
    const activeStatus = listing.status;
    const undoMode = initialStatus != activeStatus && _.includes([ARCHIVED, STALLED], activeStatus);
    const notInitiallyStalled = initialStatus != STALLED;
    const isDraft = listing.status == 'draft';
    const transferStatus = _.get(listing.active_transfer, 'status');

    return (
      <div className={classNames("col-6 status-change-dropup", {dropup: dropupMenu})}>
        <button
          type="button"
          className={primaryClass == true ? "btn btn-block btn-outline-secondary" : "btn btn-block btn-secondary"}
          onClick={(e) => undoMode ? action("undo") : this.showDropdown(e)}
          disabled={moved && !undoMode}
        >
          {undoMode ? "Undo" : "Change Status"}
        </button>

        {!undoMode && (
          <div className={classnames("dropdown-menu", {show: showDropdown})}>
            {notInitiallyStalled && <h6 className="dropdown-header">Move to Stall</h6>}
            {notInitiallyStalled && _.map(statusReasons[STALLED], (reason) => (
              <StatusReasonOption key={reason} action={action} status={STALLED} reason={reason}/>
            ))}
            {!isDraft && (
              <React.Fragment>
                <h6 className="dropdown-header">Transfer Listing</h6>
                {!listing.active_transfer && (
                  <TransferListingOptions listing={listing} action="initiate" />
                )}
                {listing.active_transfer && (
                  <TransferListingOptions
                    listing={listing}
                    transfer={listing.active_transfer}
                    action="cancel" />
                )}
                {transferStatus == "accepted" && (
                  <TransferListingOptions
                    listing={listing}
                    transfer={listing.active_transfer}
                    action="complete" />
                )}
              </React.Fragment>
            )}
            {!listing.active_transfer && (
              <React.Fragment>
                <h6 className="dropdown-header">Move to Archive</h6>
                {_.map(statusReasons[ARCHIVED], (reason) => (
                  <StatusReasonOption key={reason} action={action} status={ARCHIVED} reason={reason}/>
                ))}
              </React.Fragment>
            )}
          </div>
        )}
      </div>
    );
  }
}
