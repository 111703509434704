import Spinner from 'react-bootstrap/Spinner';

const LISTINGS_PATHS = {
  expiringSoon: '/listings/expiring_soon',
  new: '/listings/new',
  active: '/listings/active'
}

export const SectionTitle = ({text}) => (
  <div className="listing-title"><h3>{text}</h3></div>
);

export const LinkCard = ({linkTo, text}) => (
  <div className="card-column">
    <a href={LISTINGS_PATHS[linkTo]} className="new-card link-card">
      <i className="icon-right-circle"></i>
      {text}
    </a>
  </div>
);

export const ResultsMessage = ({count, loading}) => (count == 0 &&
  <div className={classnames('message', {'no-results': count == 0 || loading, loading})}>
    {loading ? (
      <div><span>loading</span> <Spinner animation="border" variant="primary" /></div>
    ) : (
      <div><h3>No Results</h3><p>Sorry, there are no horses that match your search.</p></div>
    )}
  </div>
);
