import { addFavorite, deleteFavorite } from './shared/listing_helpers';

export default class FavoriteButton extends React.Component {
  state = {favorite:  this.props.favorite}

  toggle = (e) => {
    e.stopPropagation();
    this.setState(({favorite}) => ({favorite: !favorite}), this.persistFavorite);
  }

  persistFavorite = () => {
    const {id} = this.props;
    if (this.state.favorite) {
      addFavorite(id)
    } else {
      deleteFavorite(id);
    }
  }

  render() {
    const heart = this.state.favorite ? "icon-heart" : "icon-heart-empty"

    return (
      <button
        type="button"
        className='btn btn-outline-primary btn-block'
        onClick={this.toggle}
      >
        Favorite <i className={heart} />
      </button>
    )
  }
}
