import PropTypes from 'prop-types';

/*eslint no-useless-escape: "off"*/
const EMAIL_PATTERN = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export default class Input extends React.Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      charLeft: this.props.limit? this.props.value? (this.props.limit - this.props.value.length) : this.props.limit : ''};
  }

  handleChange(e) {
    var prefix = this.props.prefix || '';
    var suffix = this.props.suffix || '';

    var event = {
      target: {
        name: this.props.name,
        value: e.target.value.replace(prefix, '').replace(suffix, '')
      }
    }

    if (this.props.isEmail) {
      if (this.validateEmail(event.target.value))
        this.props.onChange(event);
    } else {
      this.props.onChange(event);
    }

    e.target.value = prefix + event.target.value + suffix;

    this.updateLimit(e.target.value);
  }

  updateLimit(value) {
    var charLeft = '';
    if (this.props.limit)
      charLeft = this.props.limit - value.length;

    this.setState({
      charLeft: charLeft
    });
  }

  validateEmail(email) {
    return EMAIL_PATTERN.test(email);
  }

  render() {
    const { name, placeholder, value, limit } = this.props;

    return (
      <div className="input-element">
        <input
          name={name}
          className="form-control"
          type='text'
          autoComplete="false"
          placeholder={placeholder}
          value={value || ''}
          maxLength={limit || ''}
          onChange={e => this.handleChange(e)}
          onFocus={this.props.onFocus ? e => this.props.onFocus(e) : null} />
        { limit ? <span className="limit">{this.state.charLeft}</span> : ''}
      </div>

    );
  }
}
