import { eq } from 'lodash/fp';
import { useState } from 'react';
import { titleize } from 'components/shared/humanize';
import { DISPLAY_NAMES } from '../shared/csv_helpers';

export const IGNORE_VALUE = "*IGNORE THIS FIELD*";
const FIELD_HELP = {
  height: 'in hands',
  birth_year: 'year, ex 2022, but assumes values less than 50 are ages',
  price: 'USD',
};

const isDuplicate = (mapping, header) => mapping[header] !== IGNORE_VALUE && (
  _.filter(_.values(mapping), eq(mapping[header])).length > 1
);

const MappingRow = ({header, required, previewRow, csvFields, selected, setMapping, duplicate}) => (
  <tr>
    <td>
      {titleize(DISPLAY_NAMES[header] || header)}
      {required && '*'}
      {FIELD_HELP[header] && <span className="text-muted font-italic"> ({FIELD_HELP[header]})</span>}
    </td>
    <td className="py-2">
      <select className={classnames("form-control form-control-sm", {"is-invalid": (required && !selected) || duplicate})}
        value={selected}
        onChange={e => setMapping(e.target.value)}
        required={required}
      >
        <option></option>
        {header !== 'name' && <option>{IGNORE_VALUE}</option>}
        {csvFields.map((field) => <option key={field}>{field}</option>)}
      </select>
    </td>
    <td className="pl-4 font-italic d-none d-sm-table-cell">
      {previewRow[selected]}
    </td>
  </tr>
);

export default function CSVMappingTable({
  preview, mapping, setMapping, requiredHeaders, supportedHeaders
}) {
  if (!preview) return <div>loading....</div>;

  const [previewIndex, setPreviewIndex] = useState(0);
  const {data, meta} = preview ?? {};
  return (
    <table className="table">
      <thead>
        <tr>
          <th className="text-nowrap">Right Horse Field</th>
          <th className="text-nowrap" style={{minWidth: '150px'}}>CSV Field</th>
          <th className="d-none d-sm-table-cell">
            <button className="btn btn-link btn-sm py-0"
              onClick={() => setPreviewIndex(previewIndex - 1)}
              disabled={previewIndex === 0}>
              ←
            </button>
            Data Preview (row {previewIndex + 1})
            <button className="btn btn-link btn-sm py-0"
              onClick={() => setPreviewIndex(previewIndex + 1)}
              disabled={previewIndex == preview.data.length - 1}>
              →
            </button>
          </th>
        </tr>
      </thead>
      <tbody>
        {supportedHeaders.map((header) => (
          <MappingRow key={header}
            header={header}
            required={_.includes(requiredHeaders, header)}
            previewRow={data[previewIndex]}
            csvFields={meta.fields}
            selected={mapping[header]}
            setMapping={(value) => setMapping({...mapping, [header]: value})}
            duplicate={mapping[header] && isDuplicate(mapping, header)}/>
        ))}
      </tbody>
    </table>
  );
};
