import DocumentUpload from '../DocumentUpload';

export default class StepFour extends React.Component {
  render() {
    const { documents } = this.props;

    return (
      <div>
        <DocumentUpload
          label="Upload medical records, registrations, or vet assessments here."
          required={false}
          files={documents}
        />
      </div>
    );
  }
}
