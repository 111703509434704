export default function InfoBlock({icon, number, words}) {
  return (
    <div className="info-block">
      <svg className={`svg-icons ${icon}`}>
        <use href={`#${icon}`}/>
      </svg>
      <div className="info-text">
        <div className="import-listings-numbers">{number}</div>
        <div className="import-listings-words">{words}</div>
      </div>
    </div>
  )
}
