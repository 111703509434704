import Papa from 'papaparse';
import levenshtein from 'js-levenshtein';
export const DISPLAY_NAMES = {
  external_id: 'id',
  price: 'adoption fee'
};

export const guessFieldMapping = (field, csvFields) => {
  if (!field) return;
  return getClosestBySpelling(csvFields, field) || guessFieldMapping(DISPLAY_NAMES[field], csvFields);
};

const previousMappingIfPresent = (previousField, fields) => (
  _.includes(fields, previousField) ? previousField : undefined
);

export const guessMappings = (supportedHeaders, CSVHeaders, currentMapping) => (
  _.reduce(supportedHeaders, (mapping, field) => ({...mapping,
    [field]: (
      guessFieldMapping(field, CSVHeaders)
      || previousMappingIfPresent(mapping[field], CSVHeaders)
    )
  }), currentMapping)
);

export const parseCSVPreview = (file, options={}) => (
  new Promise((resolve, reject) => Papa.parse(file, {
    header: true,
    preview: 20,
    skipEmptyLines: true,
    worker: true,
    ...options,
    complete: resolve,
    error: reject
  }))
);
function getClosestBySpelling(csvFields, field) {
  return _.minBy(['', ...csvFields], (csvField) => {
    if (!csvField) return field.length / 2;
    const lengthDifference = Math.abs(csvField.length - field.length);
    const distance = levenshtein(field, _.lowerCase(csvField)) - lengthDifference / 2;
    return distance;
  });
}

