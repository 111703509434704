import moment from 'moment';
import {
  ResponsiveContainer, LineChart, CartesianGrid, XAxis, YAxis, Tooltip, Line, Label
} from 'recharts';

const dateFormat = (date) => moment(date).format('MMM D');

const pluralize = (label, value) => value === 1 ? `${label}s` : label

export default function({data, dateRange}) {
  const {total, top_five=[], by_day, dataKey, dataColor, by_adoption, tooltipLabel} = data;

  return <React.Fragment>
    <ResponsiveContainer height={130}>
      <LineChart data={by_day} margin={{top: 7}}>
        <CartesianGrid vertical={false}/>
        <XAxis dataKey="name" tick={false}>
          <Label value={dateFormat(_.first(by_day).name)} offset={0} position="insideBottomLeft" dy={-15}/>
          <Label value={dateFormat(_.last(by_day).name)} offset={0} position="insideBottomRight" dy={-15}/>
        </XAxis>
        <YAxis mirror axisLine={false} tickLine={false} tickSize={0} dx={-2} dy={4}/>
        <Tooltip contentStyle={{padding: "5px 10px"}}
          labelFormatter={(date) => moment(date).format('MMM D, YYYY')}
          formatter={(value, name, props) => (
            <div>
              <span className="legend-swatch" style={{background: dataColor}}/>
              {value} {tooltipLabel ? pluralize(tooltipLabel, value) : name}
            </div>
          )}/>
        <Line dataKey={dataKey} dot={false} stroke={dataColor} activeDot={{r: 7, strokeWidth: 0}}/>
      </LineChart>
    </ResponsiveContainer>
    <dl className="graph-total">
      <dt>Total</dt>
      <dd>{total.toLocaleString()}</dd>
    </dl>
    {top_five.length > 0 && <h6>Top Listings</h6>}
    <table className="legend">
      <tbody>
        {top_five.map(({name, count}, i) => (
          <tr key={name+i}>
            <td>{i+1}. {name}</td>
            <td>{count.toLocaleString()}</td>
          </tr>
        ))}
      </tbody>
    </table>
    {by_adoption && (
      <div className="bottom-stats">
        <table>
          <tbody>
            <tr>
              {by_adoption.map(({count, label}, i) => (
                <td key={label+i}>
                  <label>{label}</label>
                  <span className="count">{count}</span>
                </td>
              ))}
            </tr>
          </tbody>
        </table>
      </div>
    )}
  </React.Fragment>;
}
