import axios from 'axios';

import KeyRow from './APIKeys/components/KeyRow';

export default class APIKeys extends React.Component {
  constructor(props) {
    super(props);
    this.state = {apiKeys: props.api_keys}
  }

  createKey = () => {
    const {apiKeys} = this.state;
    const csrfToken = _.get(document.querySelectorAll('meta[name="csrf-token"]'), '[0].content');

    return axios.post('/api_keys', {}, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken
        },
      })
      .then(({data}) => {
        this.setState(({apiKeys}) => ({apiKeys: [...apiKeys, data]}));
      })
      .catch((error) => console.log(error));
  }

  render() {
    const {apiKeys} = this.state;

    return (
      <div>
        <table className="table table-shaded">
          <thead>
            <tr>
              <th className="created-column">Created</th>
              <th className="label-column">Label</th>
              <th className="key-column">API Key</th>
              <th className="status-column">Status</th>
            </tr>
          </thead>
          <tbody>
            {_.map(apiKeys, ((api_key, i) => {
              const {auth_token} = api_key;
              return (
                <KeyRow key={auth_token} {...api_key}/>
              )})
            )}
          </tbody>
        </table>
        <div className="text-center">
          <button className="btn btn-outline-primary" onMouseDown={this.createKey}>Create a Key</button>
        </div>
      </div>
    )
  }
}
