import LightBox from 'react-images';

export default class Gallery extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      images: this.props.images,
      open: false,
      currentImage: 0,
      width:0,
      height:100
    }
  }

  imageNumber(number) {
    return number%this.state.images.length;
  }

  openLightbox(e, currentImage = 0) {
    e.preventDefault();
    this.setState({open: true, currentImage});
  }

  closeLightbox(_e) {
    this.setState({open: false});
  }

  selectNextImage(_e) {
    const { currentImage } = this.state;
    this.setState({currentImage: this.imageNumber(currentImage+1)});
  }

  selectPreviousImage(_e) {
    const { currentImage } = this.state;
    this.setState({currentImage: this.imageNumber(currentImage-1)});
  }

  render() {
    const {open, currentImage, images} = this.state;
    const {name} = this.props.listing;
    const {name: orgName} = this.props.organization;

    return (
      <div className="gallery">
        <div className="images">
          {images.map((image, index) => {
            const imageAlt = `My Right Horse: ${name} ${orgName} photo ${index+1}`;
            return (
              <img
                src={image.medium_src}
                key={image.medium_src}
                style={{ maxHeight: '340px' }}
                onClick={e => this.openLightbox(e, index)}
                alt={imageAlt}
              />
            )
          })}
        </div>
        <div className="centered-buttons">
          <a className="btn btn-primary view-more" href="#" onClick={e => this.openLightbox(e)} >View photos</a>
        </div>
        <LightBox
          images={this.props.images}
          isOpen={open}
          onClose={e => this.closeLightbox(e)}
          onClickNext={e => this.selectNextImage(e)}
          onClickPrev={e => this.selectPreviousImage(e)}
          currentImage={currentImage}
          backdropClosesModal={true}
        />
      </div>
    );
  }
}
