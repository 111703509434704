let loading = false

export default async function loadPinterest() {
  if (!window.PinUtils && !loading) {
    loading = new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.type = 'text/javascript'
      script.src = "//assets.pinterest.com/js/pinit.js";
      const ready = () => setTimeout(() => window.PinUtils ? resolve() : ready(), 100);
      script.onload = () => {
        ready();
        setTimeout(resolve, 500);
      };
      document.head.appendChild(script);
    })
  }

  await loading;
  return window.PinUtils;
}
