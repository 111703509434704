import { ResponsiveContainer, PieChart, Pie, Cell, Label, Sector } from 'recharts';
import { MultiLineLabel } from '../components/MultiLineLabel';

export default class AverageDonut extends React.Component {
  render() {
    const {inputData, color, top5Label} = this.props.data;
    const dataCircumference = 365;

    const average = _.sumBy(inputData, 'value')/_.size(inputData);
    const hiddenSegmentSize = Math.max(dataCircumference-average, 0);

    const pieSegments = [
      {value: average, color},
      {value: hiddenSegmentSize, hidden: true}
    ];

    return <React.Fragment>
      <ResponsiveContainer height={140}>
        <PieChart>
          <circle cx="50%" cy="50%" r="60" fill="none" stroke="#bbb" />
          <Pie data={pieSegments}
            dataKey="value"
            activeShape={
              ({innerRadius, ...props}) => <Sector {...props} innerRadius={innerRadius*0.8}/>
            }
            innerRadius="80%"
            outerRadius="100%"
            startAngle={90}
            endAngle={360+90}
          >
            {pieSegments.map(({name, color, hidden}) => (
              <Cell key={name||'none'} fill={hidden ? 'transparent' : color} strokeWidth={0}/>
            ))}
            <Label position="center" className="pie-label" dy={4} content={<MultiLineLabel/>}>
              {'Days'}
            </Label>
            <Label position="center" className="pie-value" dy={12} fill="">
              {_.round(average)}
            </Label>
          </Pie>
        </PieChart>
      </ResponsiveContainer>
      <table className="legend">
        <tbody>
          <tr key='heading'><td style={{textAlign: 'left', fontSize: '12pt'}} colSpan='2'>{top5Label}</td></tr>
          {_.take(inputData, 5).map(({name, labelValue, color}, i) => (
            <tr key={name}>
              <td>{i+1}. {name}</td>
              <td>{labelValue}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </React.Fragment>;
  }
}
