import { useContext } from 'react';

import StepContext from '@/contexts/StepContext';
import ListingContext from '../../../../contexts/ListingContext';
import { stepHasErrors } from '../../helpers/hasErrors';

function stepIcon({complete, skip, error, step, currentStep}) {
  if (skip) return <i className="icon-xmark-solid"></i>;
  if (complete) return <i className="icon-check-solid"></i>;
  if (error && step != currentStep) return <i className="icon-exclamation"></i>;
  return currentStep;
}

function stepSubText({active, skip}) {
  if (active || skip) return null;
  return <span className="step-title-subtext">Click here to modify</span>;
}

export default function InfoStep({currentStep, className, children, title, skip}) {
  const {errors} = useContext(ListingContext);
  const {step, gotoStep} = useContext(StepContext);

  const hasErrors = stepHasErrors(errors, currentStep);

  const active = String(currentStep) === String(step);
  const complete = parseInt(currentStep, 10) < parseInt(step, 10) && !hasErrors;

  return (
    <div
      className={classnames(className, {active, skip, 'has-errors': hasErrors}, 'step')}
      onClick={() => gotoStep(currentStep)}
    >
      <h5>
        <span className="step-number">{stepIcon({step, complete, skip, currentStep, error:hasErrors})}</span>
        <div className="d-inline-block">
          <div className="d-flex flex-column justify-content-center">
            <span className="step-title">{title}</span>
            {stepSubText({active, skip})}
          </div>
        </div>
      </h5>
      {active ? children : null}
    </div>
  )
}
