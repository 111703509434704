export default {
  load: () => {
    try {
      if (localStorage.getItem('terms') && localStorage.getItem('terms') != 'undefined') {
        const localStorageTerms = JSON.parse(localStorage.getItem('terms'));
        localStorageTerms.location = _.defaultTo(localStorageTerms.location, '');
        return localStorageTerms;
      }
    } catch (e) {
      if (e instanceof DOMException && e.message === "The operation is insecure.") {
        return {location: ''};
      }

      throw e;
    }
  },

  save: (terms) => {
    try {
      localStorage.setItem('terms', JSON.stringify(_.omit(terms, "limit", "offset")));
    } catch (e) {
      if (e instanceof DOMException && e.message === "The operation is insecure.") {
        return;
      }

      throw e;
    }
  },

  clear: () => {
    try {
      localStorage.removeItem('terms');
    } catch (e) {
      if (e instanceof DOMException && e.message === "The operation is insecure.") {
        return;
      }

      throw e;
    }
  }
}
