import { preventDefault } from 'shared/events';
export { listToSelectOptions } from '../../Fields/Select';

const selectedOptions = ({ options, values }) => {
  const acceptableValues = _.map(options, _.first);
  const valid = (value) => _.includes(acceptableValues, value);
  return _.filter(values, valid);
}

const listToBoolMap = (list) => {
  return _.reduce(list, (values, value) => ({...values, [value]: true }), {});
}

export default class MultiSelect extends React.Component {
  constructor(props) {
    super(props);
    const values = listToBoolMap(selectedOptions(props));

    this.state = { values };
  }

  toggleSelection = (value) => {
    const checked = !_.get(this.state.values, value);

    this.setState((state) => {
      return _.set(state.values, value, checked);
    });

    this.setState(({values}) => {
      this.props.updateField(_.reduce(values, (selectedValues, checked, value) => {
        if (checked) {
          return [...selectedValues, value];
        } else {
          return selectedValues;
        }
      }, []));
    });
  }

  render() {
    const {label, required, options, error=[]} = this.props;
    const { values } = this.state;
    const requiredField = required ? '*' : null;

    const checked = (value) => !!values[value];

    return (
      <div className={classnames("form-group", {error: error.length})}>
        <label>{label} {requiredField}</label>
        <div className="error-message">{ _.capitalize(error.join(', ')) }</div>
        <div className="multi-select-options">
          {_.map(options, ([value, display]) => (
            <div className="form-control border-0" key={value}>
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id={value}
                  readOnly={true}
                  checked={checked(value)}
                />
                <label className="custom-control-label"
                  htmlFor={value}
                  onClick={preventDefault(this.toggleSelection, value)}
                >
                  {display}
                </label>
              </div>
            </div>
          ))}
        </div>
      </div>
    )
  }
}
