import request from 'axios';

const csrfToken = _.get(document.querySelectorAll('meta[name="csrf-token"]'), '[0].content');
const headers = {
  'Accept': 'application/json',
  'Content-Type': 'application/json',
  'X-CSRF-Token': csrfToken
};

export function getEntities(url) {
  return request({
    method: 'GET',
    url: url,
    headers
  });
}

export function createEntity(url, data) {
  return request({
    method: 'POST',
    url: url,
    headers,
    data: data
  });
}

export function updateEntity(url, data) {
  return request({
    method: 'PATCH',
    url: url,
    headers,
    data: data
  });
}
