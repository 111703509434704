import AdoptedButton from './buttons/AdoptedButton';
import EditButton from './buttons/EditButton';
import ChangeStatusButton from './buttons/ChangeStatusButton';

export default function ExpirationButtons({
    submitListingAction, listing, initialStatus, moved, statusReasons
}) {
  const extended = listing.percent_remaining === "100.0%";

  return (
    <div>
      <div className="row">
        <div className={listing.active_transfer ? "col-12" : "col-6"}>
          <button
            type="button"
            className="btn btn-block btn-outline-primary"
            onClick={() => submitListingAction("published")}
            disabled={extended}
          >
            {status === "expired" ? "Relist" : "Extend"}
          </button>
        </div>
        {!listing.active_transfer && (
          <AdoptedButton
            listing={listing}
            action={submitListingAction}
            primaryClass
            moved={moved}
            statusReasons={statusReasons}/>
        )}
      </div>
      <div className="row">
        <EditButton listingId={listing.id} primaryClass/>
        <ChangeStatusButton
          listing={listing}
          action={submitListingAction}
          initialStatus={initialStatus}
          primaryClass
          dropupMenu
          moved={moved}
          statusReasons={statusReasons}/>
      </div>
    </div>
  );
}
