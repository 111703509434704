import {eq} from "lodash/fp";

export default function hasErrors(errors, stepKeys) {
  if (_.isEmpty(errors)) return false;
  const errorKeys = _.keys(errors);
  return _.some(stepKeys, (key) => _.some(errorKeys, eq(key)));
}

export function errorsWithFullMessages(errorMap) {
  return _.mapValues(errorMap, (errors, key) => {
    return _.map(errors, (error) => `${key} ${error}`)
  });
}

export const STEP_KEYS = {
  '1': [
    'name',
    'price',
    'breed',
    'gender',
    'color',
    'age',
    'height',
    'intake_date',
    'microchip_serial_number',
    'temperament',
    'description'
  ],
  '2': ['location_id', 'disciplines'],
  '3': ['videos', 'listing_media'],
  '4': ['documents'],
  '5': [
    'behavior_profile_evaluator_name',
    'behavior_profile_evaluation_date'
  ],
}

STEP_KEYS['preview'] = _.uniq(_.flatten(_.values(STEP_KEYS)));

export const stepHasErrors = (errors, step) => hasErrors(errors, STEP_KEYS[String(step)]);
