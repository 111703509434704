export default function SVGIcons() {
  return <svg version="1.1" id="icons" style={{display:'none'}}>
    <symbol id="report" viewBox="0 10 110 90">
      <line className="st0" x1="35.5" y1="51.4" x2="102.8" y2="51.4"/>
      <line className="st0" x1="35.5" y1="66.4" x2="101.8" y2="66.4"/>
      <line className="st0" x1="35.5" y1="81.4" x2="95.8" y2="81.4"/>
      <line className="st0" x1="35.5" y1="96.4" x2="79.8" y2="96.4"/>
      <circle className="st0" cx="54.9" cy="54.9" r="48.9"/>
      <polyline className="st0" points="23,91 23,36.4 99.4,36.4 "/>
    </symbol>
    <symbol id="checkmark" viewBox="0 10 110 90">
      <circle className="st0" cx="54.9" cy="54.1" r="48.9"/>
      <polyline className="st0" points="22.7,49.5 35.2,71.1 87.1,41.1 "/>
    </symbol>
    <symbol id="x" viewBox="0 10 110 90">
      <circle className="st0" cx="55.9" cy="55.1" r="48.9"/>
      <line className="st0" x1="34.7" y1="76.3" x2="77.1" y2="33.9"/>
      <line className="st0" x1="34.7" y1="33.9" x2="77.1" y2="76.3"/>
    </symbol>
  </svg>
}
