import InfoStepOne from '../ListingWizard/components/steps/InfoStepOne';
import InfoStepTwo from '../ListingWizard/components/steps/InfoStepTwo';
import InfoStepThree from '../ListingWizard/components/steps/InfoStepThree';
import InfoStepFour from '../ListingWizard/components/steps/InfoStepFour';
import InfoStepFive from '../ListingWizard/components/steps/InfoStepFive';
import InfoPreviewStep from '../ListingWizard/components/steps/InfoPreviewStep';


export default function InfoBox() {
  return (
    <div className="info-box">
      <div className="steps">
        <InfoStepOne/>
        <InfoStepTwo/>
        <InfoStepThree/>
        <InfoStepFour/>
        <InfoStepFive/>
        <InfoPreviewStep/>
      </div>
    </div>
  );
}
