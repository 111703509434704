export const MultiLineLabel = ({viewBox, children, className, dy}) => {
  const lines = children.replace('/', '/ ').split(' ');
  const {cx, cy} = viewBox;
  return (
    <text x={cx} y={cy + dy} className={className} textAnchor="middle" dominantBaseline="central">
      {lines.reverse().map((line, i) => (
        <tspan key={i} alignmentBaseline="middle" x={cx} dy="-1em">{line}</tspan>
      ))}
    </text>
  );
}
