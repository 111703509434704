import Modal from "react-bootstrap/Modal";
import { Typeahead } from 'react-bootstrap-typeahead';

const modalContent = {
  'accept': {
    'TITLE': "Accept Transfer",
    'STATUS_VALUE': "accepted",
    'BUTTON_TEXT': "Accept Transfer"
  },
  'decline': {
    'TITLE': "Decline Transfer",
    'STATUS_VALUE': "declined",
    'BUTTON_TEXT': "Yes, Decline Transfer"
  },
  'cancel': {
    'TITLE': "Cancel Transfer",
    'STATUS_VALUE': "canceled",
    'BUTTON_TEXT': "Yes, Cancel Transfer"
  },
  'complete': {
    'TITLE': "Complete Transfer",
    'STATUS_VALUE': "completed",
    'BUTTON_TEXT': "Yes, Complete Transfer"
  }
}

export default class TransferListingCard extends React.Component {
  state = {
    isOpen: false,
    csrfToken: '',
    action: 'cancel',
    locationOptions: this.props.locations,
    selectedLocation: []
  };

  componentDidMount() {
    const csrfToken = _.get(document.querySelector('[name=csrf-token]'), 'content');
    this.setState({csrfToken});
  }

  disableButton = () => (
    this.state.action == 'accept' && _.isEmpty(this.state.selectedLocation)
  )

  render() {
    const {transferType, transfer, listingName, orgName} = this.props;
    const {isOpen, csrfToken, action, locationOptions, selectedLocation} = this.state;

    const showButtonGroup = (targetType, targetStatus) => {
      return transferType == targetType && transfer.status == targetStatus
    }

    return (
      <div>
        {showButtonGroup("inbound", "initiated") && (
          <div className="row">
            <div className="col-6">
              <button
                className="btn btn-block btn-outline-primary"
                type="button"
                onClick={() => this.setState({isOpen: true, action: "decline"})}
              >Decline</button>
            </div>
            <div className="col-6">
              <button
                className="btn btn-block btn-primary"
                type="button"
                onClick={() => this.setState({isOpen: true, action: "accept"})}
              >Accept</button>
            </div>
          </div>
        )}
        {(showButtonGroup("outbound", "initiated") || transfer.status == "accepted") && (
          <div className="row">
            <div className="col-12">
              <button
                className="btn btn-block btn-outline-primary"
                type="button"
                onClick={() => this.setState({isOpen: true, action: "cancel"})}
              >Cancel Transfer</button>
            </div>
          </div>
        )}
        {showButtonGroup("outbound", "accepted") && (
          <div className="row">
            <div className="col-12">
              <button
                className="btn btn-block btn-primary"
                type="button"
                onClick={() => this.setState({isOpen: true, action: "complete"})}
              >Complete Transfer</button>
            </div>
          </div>
        )}
        <Modal show={isOpen} onHide={() => this.setState({isOpen: false})} centered>
          <form acceptCharset="UTF-8" action={`/listing_transfers/${transfer.id}`} method="post">
            <Modal.Header closeButton>
              <Modal.Title>{modalContent[action]['TITLE']}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {(action === 'decline' || action === 'cancel') && (
                <p>
                  Are you sure that you would like to {action} {}
                  the transfer of <strong>{listingName}</strong> {}
                  {transferType == "inbound" ? "from" : "to"} {}
                  <strong>{orgName}</strong>?
                </p>
              )}
              {action === 'accept' && (
                <React.Fragment>
                  <label htmlFor="location_id">Please selection the location where you would like <strong>{listingName}</strong> to be transferred</label>
                  <Typeahead
                    id="location_id"
                    name="location_id"
                    placeholder="Location"
                    options={locationOptions}
                    selected={selectedLocation}
                    onChange={(selected) => this.setState({selectedLocation: selected})}
                    labelKey="name" />
                  <input
                    name="listing_transfer[location_id]"
                    readOnly
                    type="hidden"
                    value={_.get(selectedLocation[0], 'id') || ''}
                    onChange={_.noop} />
                </React.Fragment>
              )}
              {action === 'complete' && (
                <React.Fragment>
                  <p>
                    This will archive your listing for <strong>{listingName}</strong> {}
                    and create a new, unpublished listing for <strong>{orgName}</strong>.
                  </p>
                  <p>Are you sure that you would like to complete the transfer?</p>
                </React.Fragment>
              )}
              <input name="_method" readOnly type="hidden" value="patch" />
              <input
                name="listing_transfer[status]"
                readOnly type="hidden"
                value={modalContent[action]['STATUS_VALUE']} />
              <input name="authenticity_token" readOnly type="hidden" value={csrfToken} />
            </Modal.Body>
            <Modal.Footer>
              <button
                className="btn btn-primary"
                type="submit"
                disabled={this.disableButton()}
              >{modalContent[action]['BUTTON_TEXT']}</button>
            </Modal.Footer>
          </form>
        </Modal>
      </div>
    );
  }
}
