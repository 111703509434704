import ReactPlayer from 'react-player';

const emptyString = v => v === '';
const negate = func => (...args) => !func(...args);

export default class VideoGallery extends React.Component {
  render() {
    const { videos } = this.props;
    const width = $('.details').width();
    const height = width*(9/16);
    const videoUrls = videos.filter(negate(emptyString));

    return (
      <div className="video-gallery">
        <h4>Video</h4>
        {videoUrls.map((url) => (
          <ReactPlayer className="video" url={url} width={width} height={height} key={url} />
        ))}
      </div>
    );
  }
}
