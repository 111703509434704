import { useEffect, useState, useContext } from 'react';
import Dropzone from 'react-dropzone';
import {concat, map} from 'lodash/fp';

import { preventDefault } from 'shared/events';
import flash from 'shared/flash';

import ListingContext from '@/contexts/ListingContext';

const ALLOWED_DOCUMENTS = 10;

function fileCount(uploading, files) {
  if (uploading) {
    return "Uploading documents";
  } else {
    const moreDocuments = ALLOWED_DOCUMENTS - _.size(files);
    return `Upload up to ${moreDocuments} more documents.`;
  }
}

export default function DocumentUpload({files:value, label, required}) {
  const [files, setFiles] = useState(value);
  const [uploading, setUploading] = useState(false);
  const [saveStatus, setSaveStatus] = useState();
  const {uploadAttachments, deleteAttachment} = useContext(ListingContext);

  useEffect(() => {
    if(saveStatus) {
      flash(...saveStatus);
      setSaveStatus(null);
    }
  }, [saveStatus]);
  useEffect(() => { setFiles(value); }, [value]);

  const onDrop = async (acceptedFiles) => {
    setUploading(true);

    setFiles(concat(map(({ name, preview }) => ({
      uploading: true,
      id: preview,
      document_name: name
    }), acceptedFiles)));

    try {
      await uploadAttachments('documents', acceptedFiles);
      setSaveStatus(['success', 'Uploaded Documents.']);
    } catch (e) {
      console.warn(e);
      setSaveStatus(['error', "Error uploading."]);
    }
    setUploading(false);
  };

  const removeDocument = async (id) => {
    try {
      await deleteAttachment('documents', id);
    } catch(e) {
      console.warn(e);
      setSaveStatus(['error', 'Unable to delete image']);
    }
  }

  const requiredField = required ? '*' : null;

  return (
    <div className="form-group">
      <label>{label} {requiredField}</label>
      <Dropzone className="dropzone d-flex justify-content-center" onDrop={onDrop}>
        <span className="label">
          <a
            className="btn btn-primary mr-2 px-4"
            disabled={uploading}
            href="#">
            Upload Documents
          </a>
          or drag files here.
        </span>
      </Dropzone>
      <div className="file-preview pb-3">
        <div className="row">
          <div className="file-count">
            { fileCount(uploading, files) }
          </div>
        </div>
        {_.map(files, ({id, document_name, document_extension, uploading=false}) => {
          const iconName = `icon-${document_extension}-documenticon`;
          return (
            <div key={id || document_name} className="document-row">
              <div className="row">
                <div className="document-name-column" style={{
                    opacity: uploading ? '0.2' : '1'
                }}>
                  <i className={iconName}></i>
                  <span className="document-file-name">{ document_name }</span>
                </div>
                <button
                  className="file-action-button col-md-3 col-lg-2"
                  disabled={uploading}
                  onClick={preventDefault(removeDocument, id)}
                >
                  Delete
                </button>
              </div>
              <hr/>
            </div>
          )
        })}
      </div>
    </div>
  );
}
