import ReactSlider from 'react-slider';
import { Popover, PopoverBody } from 'reactstrap';

export default class Slider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {value: props.value, popoverOpen: false};
  }

  toggle = (e) => {
    e.preventDefault();
    this.setState({popoverOpen: !this.state.popoverOpen});
  }

  updateValue = (value) => {
    this.setState({value: value});
    this.props.updateField({target: { name: this.props.fieldName, value: value ? value : null}});
  }

  render() {
    const {minLabel, maxLabel, fieldName, min, max, step, label, valueLabels=[], helpText=[]} = this.props;
    const {value, popoverOpen} = this.state;
    var minDistance = (max-min)/20;
    const className = classnames('slider', {hasValue: value});

    return (
      <div className="form-group">
        <label>{label}</label>
        <div className="slider-field form-control">
          <div className="value-label">
            {valueLabels[_.clamp(value, 0, valueLabels.length - 1)]}
            <div className="help-toggle wizard" onClick={this.toggle} id="help_toggle">?</div>
            <Popover
              placement="top"
              isOpen={popoverOpen}
              target="help_toggle"
              toggle={this.toggle}
            >
              <PopoverBody>
                {helpText[_.clamp(value, 0, helpText.length - 1)]}
              </PopoverBody>
            </Popover>
          </div>
          <ReactSlider
            className={className}
            name={fieldName}
            value={value}
            withThumbs
            min={parseInt(min, 10)}
            max={parseInt(max, 10)}
            minDistance={minDistance}
            step={step || 1}
            onChange={this.updateValue}/>
          <div className="labels">
            <span className="label" key="min">{minLabel || min}</span>
            <span className="label right" key="max">{maxLabel || max}</span>
          </div>
        </div>
      </div>
    );
  }
}
