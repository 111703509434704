import moment from 'moment';
import { Row, Col } from 'reactstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import { createEntity } from './libs/requestsManager';

import Spinner from 'react-bootstrap/Spinner';

import DateRangePicker from './Analytics/components/DateRangePicker';
import AnalyticsCard from './Analytics/components/AnalyticsCard';
import {
  top5categories, top5listings, colorData, outcomeCategories,
  mergeData, asCollection, responsivePeriods, collectionInPercent,
  INTAKES_COLOR, LIVE_OUTCOMES_COLOR, OTHER_OUTCOMES_COLOR
} from './Analytics/data-helpers';
import { loadFrame } from './Analytics/report-helpers';

const ALL_ORGANIZATION_OPTION = {id: null, label: "All Organizations"};

const dateFormat = (date) => moment(date).format('YYYY-M-D');

const urlByActiveState = (activeOnly, from, to) => {
  return activeOnly ? '/analytics' : `/analytics?from=${dateFormat(from)}&to=${dateFormat(to)}`;
};

const organizationOptions = (publicOrganizations) => {
  const publicOrganizationOptions = publicOrganizations.map((org) => ({id: org.id, label: org.name}));
  return [ALL_ORGANIZATION_OPTION, ..._.sortBy(publicOrganizationOptions, ['label'])];
};

const dateRangeByActiveState = (from, to, rangeName) => rangeName === "Active Listings" ? [null, null] : [from, to];

const dateParamsFromURL = () => {
  const urlParams = new URLSearchParams(window.location.search);
  return {from: urlParams.get('from'), to: urlParams.get('to')};
};

export default class AnalyticsOverview extends React.Component {
  constructor(props) {
    super(props);
    const {activeOnly, organization, statistics} = props;

    this.state = {
      activeOnly,
      selectedOrganization: {id: organization.id, label: organization.name},
      statistics
    };
  }

  componentDidMount() {
    if (window.location.hash === '#report') {
      window.addEventListener("message", this.updateIframeState);
    } else {
      if (this.props.statistics === null) {
        const {from, to} = dateParamsFromURL();
        this.postAnalytics(this.state.selectedOrganization.id, from, to);
      }
    }
  }
  componentWillUnmount() {
    window.removeEventListener("message", this.updateIframeState);
  }

  updateIframeState = (e) => {
    if (e.data) {
      this.setState({iframe: true})
      this.setState(e.data, () => parent.postMessage({type: "loaded"}, window.location.href));
    }
  }

  updateDateRange = (date_range, name) => {
    this.setState(({statistics}) => {
      return {
        statistics: {...statistics, date_range},
        loading: true,
        activeOnly: name === "Active Listings"
      };
    });

    const {from, to} = date_range;
    const url = urlByActiveState(name === "Active Listings", ...dateRangeByActiveState(from, to, name));
    history.replaceState({}, 'date range', url);

    this.postAnalytics(this.state.selectedOrganization.id, ...dateRangeByActiveState(from, to, name))
  }

  updateOrganization = (selected) => {
    const selectedOrganization = selected ? selected : {id: null, label: 'Invalid Selection'};
    if (selectedOrganization.label !== 'Invalid Selection'){
      this.setState({loading: true, selectedOrganization});

      const {from, to} = dateParamsFromURL();
      this.postAnalytics(selectedOrganization.id, from, to);
    };
  }

  postAnalytics = (id, from, to) => {
    createEntity('analytics', {organization_id: id, from, to}).then(({data}) => {
      const {statistics={}} = data;
      this.setState({statistics, loading: false});
    }).catch((error) => console.warn(error));
  }

  generatePdf = async () => {
    this.setState({generatingPdf: true});
    try {
      const markup = await loadFrame(_.omit(this.state, 'loading', 'generatingPdf'));
      const response = await createEntity('analytics_pdfs', {markup});
      window.open(response.data.url);
    } catch (error) {
      console.error(error);
    }
    this.setState({generatingPdf: false});
  }

  render() {
    const {helpText, publicOrganizations, isSupervisor} = this.props;
    const {statistics={}, loading, activeOnly, generatingPdf, selectedOrganization, iframe} = this.state;
    const {date_range} = statistics || this.props;

    return (
      <div>
        {generatingPdf && (
          <div className="generating-pdf p-2">Generating PDF, please wait <Spinner animation="border" variant="primary" /></div>
        )}
        <div className="d-flex flex-row flex-wrap my-3 justify-content-start">
          <button className="export-pdf mr-auto" onClick={this.generatePdf} disabled={loading || statistics === null}>
            Export PDF
          </button>
          {isSupervisor && (
            <div className="analytics-organizations">
              <Typeahead
                id="analytics-organizations"
                inputProps={{id: "analytics-organizations-input", name: "analytics-organizations-input"}}
                placeholder="Select or search for an organization"
                selected={[selectedOrganization]}
                options={organizationOptions(publicOrganizations)}
                onChange={([selected]) => this.updateOrganization(selected)}
                onInputChange={(string) => this.setState({selectedOrganization: string})}
              />
            </div>
          )}
          <DateRangePicker
            activeOnly={activeOnly}
            dateRange={date_range}
            onDateRangeChange={this.updateDateRange}
            loading={loading}
            iframe={iframe}
          />
        </div>

        {statistics === null ? (
          <div className="d-flex flex-row my-3 justify-content-center">
            <span className="pr-1">loading</span>
            <Spinner animation="border" variant="primary" />
          </div>
        ) : <>
          <Row className="row-eq-height">
            <Col md="6">
              <AnalyticsCard
                activeOnly={activeOnly}
                title="Listing Views"
                graphType="Historical"
                data={{
                  total: statistics.listing_views,
                  top_five: statistics.top_listing_views,
                  by_day: asCollection(statistics.listing_views_by_day, "Listing Views"),
                  dataKey: "Listing Views",
                  dataColor: "#2c4f85"
                }}
                dateRange={date_range}
                helpText={helpText}
              />
            </Col>
            <Col md="6">
              <AnalyticsCard
                activeOnly={activeOnly}
                title="Number of Shares"
                graphType="Historical"
                data={{
                  total: statistics.listing_shares,
                  top_five: statistics.top_listing_shares,
                  by_day: asCollection(statistics.listing_shares_by_day, "Listing Shares"),
                  dataKey: "Listing Shares",
                  dataColor: "#c0354c"
                }}
                dateRange={date_range}
                helpText={helpText}
              />
            </Col>
          </Row>

          {!activeOnly && <>
            <Row className="row-eq-height">
              <Col md="12">
                <AnalyticsCard
                  activeOnly={activeOnly}
                  title="Intakes and Outcomes"
                  graphType="Line"
                  data={responsivePeriods({
                    values: mergeData({
                      "Intakes": statistics.intakes_by_period_for_wide,
                      "Live Outcomes": statistics.live_outcomes_by_period,
                      "Other Outcomes": statistics.other_outcomes_by_period,
                    }),
                    series: [
                      {key: "Intakes", color: INTAKES_COLOR},
                      {key: "Live Outcomes", color: LIVE_OUTCOMES_COLOR},
                      {key: "Other Outcomes", color: OTHER_OUTCOMES_COLOR}
                    ],
                    period: statistics.period_for_wide
                  })}
                  className="show-middle-y-label"
                  dateRange={date_range}
                  helpText={helpText}
                />
              </Col>
            </Row>

            <Row className="row-eq-height">
              <Col md="4">
                <AnalyticsCard
                  activeOnly={activeOnly}
                  title="Intakes"
                  graphType="Bar"
                  data={{
                    values: asCollection(statistics.intakes_by_period_for_narrow, "Intakes"),
                    period: statistics.period_for_narrow,
                    dataKey: "Intakes",
                    dataColor: INTAKES_COLOR
                  }}
                  dateRange={date_range}
                  helpText={helpText}
                />
              </Col>
              <Col md="4">
                <AnalyticsCard
                  activeOnly={activeOnly}
                  title="Live Outcomes"
                  graphType="Donut"
                  data={outcomeCategories(statistics.outcomes, 'live')}
                  dateRange={date_range}
                  helpText={helpText}
                />
              </Col>
              <Col md="4">
                <AnalyticsCard
                  activeOnly={activeOnly}
                  title="Other Outcomes"
                  graphType="Donut"
                  data={outcomeCategories(statistics.outcomes, 'other')}
                  dateRange={date_range}
                  helpText={helpText}
                />
              </Col>
            </Row>
          </>}

          <Row className="row-eq-height">
            <Col md="4">
              <AnalyticsCard
                activeOnly={activeOnly}
                title="Average Listing Lifespan"
                graphType="AverageDonut"
                data={{
                  inputData: top5listings(statistics.listing_lifespans),
                  color: "#6D8D3B",
                  top5Label: "Longest Lifespans"
                }}
                helpText={helpText}
              />
            </Col>
            <Col md="4">
              <AnalyticsCard
                activeOnly={activeOnly}
                title="Average Length of Stay"
                graphType="AverageDonut"
                data={{
                  inputData: top5listings(statistics.stay_lengths),
                  color: "#82224E",
                  top5Label: "Longest Stays"
                }}
                helpText={helpText}
              />
            </Col>
            <Col md="4">
              <AnalyticsCard
                activeOnly={activeOnly}
                title="Horse Age"
                graphType="AgeBar"
                data={{
                  dataColor: "#2c4f85",
                  dataKey: "Total:",
                  values: collectionInPercent(statistics.listings_by_age, "Total:")
                }}
                dateRange={date_range}
                helpText={helpText}
              />
            </Col>
          </Row>

          <Row className="row-eq-height">
            <Col md="4">
              <AnalyticsCard
                activeOnly={activeOnly}
                title="Gender"
                graphType="Donut"
                data={top5categories(statistics.genders)}
                dateRange={date_range}
                helpText={helpText}
              />
            </Col>
            <Col md="4">
              <AnalyticsCard
                activeOnly={activeOnly}
                title="Top Breeds"
                graphType="TopTen"
                data={{
                  total: statistics.total_relevant_listings,
                  values: colorData(statistics.breeds)
                }}
                dateRange={date_range}
                helpText={helpText}
              />
            </Col>
            <Col md="4">
              <AnalyticsCard
                activeOnly={activeOnly}
                title="Top Disciplines"
                graphType="TopTen"
                data={{
                  total: statistics.total_relevant_listings,
                  values: colorData(statistics.disciplines)
                }}
                dateRange={date_range}
                helpText={helpText}
              />
            </Col>
          </Row>

          <Row className="row-eq-height">
            <Col md="4">
              <AnalyticsCard
                activeOnly={activeOnly}
                title="Adoption Inquiries"
                graphType="Historical"
                data={{
                  total: statistics.listing_inquiries,
                  dataKey: "Inquiries",
                  dataColor: "#1f964e",
                  by_day: asCollection(statistics.listing_inquiries_by_day, "Inquiries"),
                  by_adoption: statistics.listing_inquiries_by_adoption
                }}
                helpText={helpText}
              />
            </Col>
            <Col md="4">
              <AnalyticsCard
                activeOnly={activeOnly}
                title="BBP Analysis"
                graphType="Historical"
                data={{
                  total: statistics.bbp_analysis,
                  dataKey: "BBP Analysis",
                  dataColor: "#0A6F83",
                  by_day: asCollection(statistics.bbp_analysis_by_day, "BBP Analysis"),
                  by_adoption: statistics.bbp_analysis_by_adoption,
                  tooltipLabel: "Assessment"
                }}
                helpText={helpText}
              />
            </Col>
            <Col md="4">
              <AnalyticsCard
                activeOnly={activeOnly}
                title="Microchipped"
                graphType="Historical"
                data={{
                  total: statistics.microchipped,
                  dataKey: "Microchipped",
                  dataColor: "#66326B",
                  by_day: asCollection(statistics.microchipped_by_day, "Microchipped"),
                  by_adoption: statistics.microchipped_by_adoption
                }}
                helpText={helpText}
              />
            </Col>
          </Row>
          <Row className="row-eq-height">
            <Col md="4" className="bottom-row">
              <AnalyticsCard
                activeOnly={activeOnly}
                title="Fostering Inquiries"
                graphType="Historical"
                data={{
                  total: statistics.foster_listing_inquiries,
                  dataKey: "Fostering Inquiries",
                  dataColor: "#0C6F84",
                  by_day: asCollection(statistics.foster_listing_inquiries_by_day, "Fostering Inquiries"),
                }}
                helpText={helpText}
              />
            </Col>
          </Row>
        </>}
      </div>
    );
  };
}
