import { flow, sortBy, reverse } from 'lodash/fp';
import moment from 'moment';

export const INTAKES_COLOR = "#66326b";
export const LIVE_OUTCOMES_COLOR = "#1f964e";
export const OTHER_OUTCOMES_COLOR = "#ad812d";

const COLORS = ['#66326B', '#1F964E', '#AD812D', '#0C6F84', '#C0354C', '#6D8D3B', '#4A8A7C', '#9B1C20', '#82224E', '#2C4F85'];
const indexColor = (index) => COLORS[index] || `hsl(${77*index}, 90%, 25%)`;

export const asCollection = (data, dataKey="value") => {
  return Object.entries(data).map(([name, value]) => ({name, [dataKey]: _.round(value)}));
}

const sortOther = (data) => _.sortBy(data, ({name}) => name == 'Other');
const limitData = (max=5) => (data) => {
  if (data.length > max) {
    const originalData = data.slice(max-1);
    return [...data.slice(0, max-1), {name: "Other", value: _.sumBy(originalData, 'value'), originalData}];
  }
  return data;
};
const includePercent = (data) => {
  const total = _.sumBy(data, 'value');
  return data.map((data) => ({...data, labelValue: ((data.value || 0)/total * 100).toFixed(1) + '%'}));
};
const valuesAsPercent = (data, dataKey="value") => {
  const total = _.sumBy(data, dataKey);
  const convertToPercent = (num) => ((num/total)*100).toFixed(2);

  return data.map((datum) => ({...datum, [dataKey]: convertToPercent(datum[dataKey])}));
};
const days = (data) => {
  return data.map((data) => ({...data, labelValue: _.round(data.value)}));
};
export const colorData = (data) => data.map((data, i) => ({...data, color: indexColor(i)}));

export const top5categories = flow(asCollection, sortBy('value'), reverse, sortOther, limitData(5), includePercent, colorData);
export const top5listings = flow(asCollection, sortBy('value'), reverse, days);
export const collectionInPercent = (data, dataKey) => valuesAsPercent(asCollection(data, dataKey), dataKey);

const OUTCOMES = [
  {name: 'Adopted', color: LIVE_OUTCOMES_COLOR, section: 'live'},
  {name: 'Adopter Came From Elsewhere', color: '#0c6f84', section: 'live'},
  {name: 'Transferred', color: '#c0354c', section: 'live'},
  {name: 'Died', color: OTHER_OUTCOMES_COLOR, section: 'other'},
  {name: 'Euthanized', color: '#4a8a7c', section: 'other'},
];

export const outcomeCategories = (data, type) => {
  if (_.isEmpty(data) || !_.sum(_.values(data), 'value')) return [];
  return includePercent(
    OUTCOMES.map((outcome) => ({
      ...outcome,
      value: data[outcome.name] || 0,
      hidden: type != outcome.section
    }))
  );
};

export const mergeData = (datasets) => {
  const dates = _.uniq(_.flatMap(_.values(datasets), _.keys)).sort();
  const series = _.keys(datasets);
  return dates.map((date) => ({
    date,
    ...series.reduce((data, key) => (
      {...data, [key]: datasets[key][date]}
    ), {})
  }))
}

const periodForScreenSize = (days) => {
  const isMobile = document.documentElement.clientWidth < 540;
  if (isMobile) {
    if (days > 91) return 'week';
    else if (days > 45) return '4day';
    else return '2day';
  } else {
    if (days > 95) return '4day';
    else if (days > 35) return '2day';
  }
  return 'day';
};
const CHUNKS = {'week': 7, '4day': 4, '2day': 2};

const sumAllValues = (group, values) => Object.keys(values).reduce(
  (summedValues, key) => (
    {...summedValues, [key]: (group[key] || 0) + values[key]}
  ), {}
);

const combineChunk = (chunk) => (
  chunk.reduce((group, {date, ...values}) => (
    {date: group.date || date, ...sumAllValues(group, values)}
  ), {})
);

const groupValues = (values, period) => {
  if (period === 'day') return values;
  const firstChunkSize = period === 'week' ? 7 - moment(_.first(values).date).day() : 0;
  const firstChunk = _.take(values, firstChunkSize || 0);
  return _.compact([
    (firstChunk.length ? combineChunk(firstChunk) : null),
    ..._.chunk(_.slice(values, firstChunkSize || 0), CHUNKS[period]).map(combineChunk)
  ]);
};

export const responsivePeriods = ({values, period, ...data}) => {
  if (period !== 'day') return {values, period, ...data}
  period = periodForScreenSize(values.length);
  return {values: groupValues(values, period), period, ...data};
}
