import TextInput from '../TextInput';
import DateInput from '../DateInput';
import BooleanSelect from '../BooleanSelect';

function getValue(func, ...args) {
  return ({target: {value=''}={}}={}) => {
    func(...args, value);
  };
}

export default class StepFive extends React.Component {
  updateField = (name, value) => {
    this.props.updateField(['behavior_profile', name], value);
  }

  render() {
    const {behavior_profile={}, behavior_profile_options, errors={}} = this.props;
    const {evaluator_name, evaluation_date} = behavior_profile;
    const behaviorFieldSet = _.pick(behavior_profile, _.keys(behavior_profile_options));
    const has_errors = errors['behavior_profile_evaluator_name'] || errors['behavior_profile_evaluation_date'];

    return (
      <div>
        <div className="row">
          <div className="col-lg-7 border-0">
            <TextInput
              fieldName="name"
              label="Evaluator Name"
              required={true}
              placeholder="Evaluator Name"
              value={evaluator_name}
              updateField={getValue(this.updateField, 'evaluator_name')}
              error={errors['behavior_profile_evaluator_name']}
              has_errors={has_errors}
            />
          </div>
          <div className="col-lg-5">
            <DateInput
              fieldName="name"
              label="Date Evaluated"
              required={true}
              value={evaluation_date}
              has_errors={has_errors}
              error={errors['behavior_profile_evaluation_date']}
              updateField={getValue(this.updateField, 'evaluation_date')}
            />
          </div>
        </div>
          {_.map(behaviorFieldSet, (complete, fieldName) => (
            <BooleanSelect key={fieldName}
              complete={complete}
              label={behavior_profile_options[fieldName]}
              fieldName={fieldName}
              updateField={this.updateField}
            />
          ))}
      </div>
    )
  }
}
