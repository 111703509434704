export default class DateInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {...props};
  }

  localUpdate = (e) => this.setState({value: e.target.value});

  render() {
    const {label, required, placeholder, updateField, fieldName, error=[], has_errors} = this.props;
    const {value} = this.state;
    const requiredField = required ? '*' : null;
    return (
      <div className={classnames("form-group", {error: error.length})}>
        <label>{label} {requiredField}</label>
        <div className={classnames('error-message', {has_errors})}>
          { _.capitalize(error.join(', ')).replace(/_/g, ' ') }
        </div>
        <input type="date"
          name={fieldName}
          placeholder="yyyy-mm-dd"
          value={value || ""}
          className="form-control"
          onChange={this.localUpdate}
          onBlur={updateField}
        />
      </div>
    )
  }
}
