import { pullAt } from 'lodash/fp';
import ReactPlayer from 'react-player';
import { preventDefault } from 'shared/events';

export default class VideoLinks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {...props, newValue: ''};
  }

  localUpdate = (e) => this.setState({newValue: e.target.value})

  addVideo = (e) => {
    const value = [...(this.state.value || []), this.state.newValue];

    this.setState({value, newValue: ''});
    this.props.updateField({target: {value, name: this.props.fieldName}});
  }

  removeVideo = (index) => {
    const value = pullAt(index, this.state.value);

    this.setState({value});
    this.props.updateField({target: {value, name: this.props.fieldName}});
  }

  render() {
    const {error=[], has_errors} = this.props;
    const {value, newValue} = this.state;

    return (
      <div className={classnames("form-group", {error: error.length})}>
        <label>Share videos of your horse.</label>
        <div className={classnames('error-message', {has_errors})}>
          { _.capitalize(error.join(', ')).replace(/_/g, ' ') }
        </div>
        {value &&
          <div className="file-preview pt-4">
            <div className="card-row">
              {_.map(value, (url, index) => (
                <div className="card-column-photo" key={url + index}>
                  <div className="card bg-white">
                    <ReactPlayer className="video" url={url} width="100%" height={190}/>
                    <div className="row">
                      <div className="col-12">
                        <button type="button"
                          className="file-action-button"
                          onClick={preventDefault(() => this.removeVideo(index))}>
                          Delete
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        }
        <div className="input-group mt-4">
          <input type="text"
                 value={newValue}
                 className="form-control"
                 placeholder="Paste a YouTube or Vimeo link here"
                 onChange={this.localUpdate}/>
          <button className="btn btn-primary"
            onClick={this.addVideo}
            disabled={newValue === ''}>
            Add
          </button>
        </div>
      </div>
    )
  }
}
