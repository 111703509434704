import { useEffect, useContext, useState } from "react";

import flash from "shared/flash";

import ListingContext from "@/contexts/ListingContext";
import { titleize } from 'components/shared/humanize';

const ActionText = (published, status) => (
  <p className="actions-text">
    {published ? (
      <>
        <i className="icon-check-solid"></i>Published
      </>
    ) : (
      titleize(listing.status)
    )}
  </p>
);

export const ListingRow = ({addCoverPhoto, selectedId, hasExternalId}) => {
  const {publish, listing, errors} = useContext(ListingContext);
  const selected = selectedId === listing.id;
  useEffect(() => {
    if (!_.isEmpty(errors)) flash('errors', _.map(errors, (values, key) => (
      [_.capitalize(key), new Intl.ListFormat().format(values)].join(' ')
    )).join('\n'));
  }, [errors]);

  const [published, setPublished] = useState(false);

  const makePublished = async () => {
    await publish();
    setPublished(true);
  };

  return (
    <tr className={classnames({'table-active': selected})} onClick={selectedId && addCoverPhoto(listing.id)}>
      <td>{listing.name}</td>
      {!selectedId && <>
        {hasExternalId && <td>{listing.external_id}</td>}
        <td className="py-1">
          <a className="btn btn-outline-secondary mr-1"
            href={`/listings/${listing.id}/edit${ !_.isEmpty(errors) ? '?for-publication' : ''}`}
            target="_blank"
          >
            Edit
          </a>
          {listing.cover_image_id ? (
            listing.status === 'draft' && !published ? (
              <button type="button" className="btn btn-primary" onClick={makePublished}>Publish</button>
            ) : (
              <ActionText published={published} status={listing.status}/>
            )
          ) : (
            <button type="button" className="btn btn-primary" onClick={addCoverPhoto(listing.id)}>Add cover photo</button>
          )}
        </td>
      </>}
    </tr>
  );
};

export default ListingRow;
