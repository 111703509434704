import StatusReasonOption from 'shared/StatusReasonOption';

const ADOPTED = "adopted";
const UNDO = "undo";

export default class AdoptedButton extends React.Component {
  state = {showDropdown: false, undoMode: false}

  componentWillUnmount() {
    document.removeEventListener('click', this.hideDropdown);
  }

  showDropdown = (e) => {
    e.stopPropagation();
    this.setState({showDropdown: true});
    document.addEventListener('click', this.hideDropdown, false);
  }

  hideDropdown = () => {
    if (this.state.showDropdown) {
      this.setState({showDropdown: false});
      document.removeEventListener('click', this.hideDropdown);
    }
  }

  changeAdoptionStatus = (_status, status_reason) => {
    const {action} = this.props;
    const {undoMode} = this.state;
    this.setState({undoMode: !undoMode});
    action((undoMode ? UNDO : ADOPTED), status_reason);
  }

  render() {
    const {moved, primaryClass, statusReasons, activeTransfer} = this.props;
    const {showDropdown, undoMode} = this.state;

    return (
      <div className="col-6 status-change-dropup">
        <button
          type="button"
          className={primaryClass ? "btn btn-block btn-outline-secondary" : "btn btn-block btn-secondary"}
          onClick={undoMode ? this.changeAdoptionStatus : (e) => this.showDropdown(e)}
          disabled={activeTransfer ? activeTransfer : moved && !undoMode}
        >
          {undoMode ? "Undo" : "Adopted"}
        </button>

        {!undoMode && (
          <div className={classnames("dropdown-menu", {show: showDropdown})}>
            {_.map(statusReasons[ADOPTED], (reason) => (
              <StatusReasonOption key={reason} action={this.changeAdoptionStatus} reason={reason}/>
            ))}
          </div>
        )}
      </div>
    );
  }
}
