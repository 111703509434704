import InfoStep from './InfoStep';

export default function InfoStepTwo() {
  return (
    <InfoStep currentStep="2" title="Location & Disciplines">
      <div className="text-block">
        <p>
          {`
            Specify the location where the horse is currently residing. The primary location for
            your organization will automatically be selected if one isn't provided. This can be
            changed later.
          `}
          </p>
      </div>
      <div className="text-block">
        <p>Select only the disciplines your horse has worked in or is well suited for.</p>
      </div>
    </InfoStep>
  );
}
