import {updateEntity} from './libs/requestsManager';

const ENTER_KEY_CODE = 13;

export default class OrganizationSlugField extends React.Component {
  state = {slug: this.props.slug, editing: false, error: null};

  editSlug = () => {
    this.setState({editing: true});
  }

  setSlug = ({target: {value}}) => {
    this.setState({slug: value});
  }

  onEnter = (e) => e.charCode === ENTER_KEY_CODE ? this.saveSlug() : null;

  saveSlug = () => {
    const {id} = this.props;
    const {slug} = this.state;

    return updateEntity(`/organizations/${id}`, {slug: slug})
      .then((response) => {
        this.setState({error: null, editing: false, slug: response.data.slug});
        return response;
      })
      .catch((error) => {
        this.setState({error: error.response.data.error, editing: true});
        return error;
      });
  }

  render() {
    const {slug, editing, error} = this.state;

    return (
      <div className="container">
        { editing ? (
          <div>
            {error && <div className="error-message slug-error">{error}</div>}
            <div className="row url-field-row">
              <span className="url-field base-url">
                http://{window.location.host}/organizations/
              </span>
              <input
                className={classnames({error}, "url-field field-border editable")}
                id="organization-slug-field"
                name="organization[slug]"
                onChange={this.setSlug}
                onBlur={this.saveSlug}
                onKeyPress={this.onEnter}
                type="text"
                value={slug}
              />
              <i className="icon-saveandcircle large-icon" onClick={this.saveSlug} title="save slug"/>
            </div>
          </div>
        ) : (
          <div className="row url-field-row">
            <span className="url-field field-border saved">
              {`http://${window.location.host}/organizations/${slug}`}
            </span>
            <i className="icon-editandcircle large-icon" onClick={this.editSlug} title="edit slug"/>
          </div>
        )}
      </div>
    );
  }
}
