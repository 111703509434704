export default class ProgressiveImage extends React.Component {
  state = {fullImageLoaded: false}

  render() {
    const {srcLite, src} = this.props;
    const {fullImageLoaded} = this.state;

    return (
      <div className="progressive-image">
        {!fullImageLoaded && (
          <img src={srcLite} className="listing-card-image lite" alt="" />
        )}
        <img
          src={src}
          className={classnames('listing-card-image', {hidden: !fullImageLoaded})}
          onLoad={() => this.setState({fullImageLoaded: true})}
          alt=""
        />
      </div>
    );
  }
}
