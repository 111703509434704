import ReversibleButton from './buttons/ReversibleButton';
import EditButton from './buttons/EditButton';
import ChangeStatusButton from './buttons/ChangeStatusButton';
import AdoptedButton from './buttons/AdoptedButton';

export default function ActiveButtons({
    submitListingAction, initialStatus, listing, moved, statusReasons
}) {
  const movedToStall = listing.status === 'stalled' && initialStatus === 'published'
  const disable = moved && !movedToStall

  return (
    <div>
      <div className="row">
        <ReversibleButton
          primaryClass
          activeStatus={listing.status}
          newStatus="draft"
          action={submitListingAction}
          actionLabel="Unpublish"
          width={listing.active_transfer ? "full" : "half"}
          moved={moved}/>
        {!listing.active_transfer && (
          <AdoptedButton
            primaryClass
            listing={listing}
            action={submitListingAction}
            moved={disable}
            statusReasons={statusReasons}/>
        )}
      </div>
      <div className="row">
        <EditButton listingId={listing.id} primaryClass/>
        <ChangeStatusButton
          listing={listing}
          primaryClass
          dropupMenu
          action={submitListingAction}
          initialStatus={initialStatus}
          moved={moved}
          statusReasons={statusReasons}/>
      </div>
    </div>
  );
}
