export default function ContributorSearch({setFilterTerm, search, searchPlaceholder}) {
  return (
    <div className="input-group">
      <div className="input-group-prepend">
        <div className="input-group-text"><i className="icon-search"/></div>
      </div>
      <input type="search"
        className="form-control"
        onChange={(e) => setFilterTerm(e.target.value)}
        id="search"
        name="search"
        value={search}
        placeholder={searchPlaceholder}
      />
    </div>
  );
}
