import InfoStep from './InfoStep';

export default function InfoStepFour() {
  return (
    <InfoStep currentStep="4" title="Documents">
      <div className="text-block">
        <p>You can add any documents you want here. Think of this as a storage folder for anything important the adopter might want to know.</p>
      </div>
      <div className="text-block">
        <p>Document file types that can be uploaded here include JPEG, JPG, PNG, PDF, CSV, and DOCX.</p>
      </div>
    </InfoStep>
  );
}
