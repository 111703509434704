import EditButton from './buttons/EditButton';

export default class MoveToDraftsButtons extends React.Component {
  render() {
    const {submitListingAction, listing} = this.props;

    return (
      <div>
        {listing.status == "draft" ? (
          <div className="row">
            <div className="col-6">
              <button
                type="button"
                className="btn btn-block btn-outline-secondary"
                onClick={() => submitListingAction("undo")} >
                Undo
              </button>
            </div>
            <EditButton listingId={listing.id} primaryClass/>
          </div>
        ) : (
          <div className="row">
            <div className="col-12">
              <button
                type="button"
                className="btn btn-block btn-outline-secondary"
                onClick={() => submitListingAction("draft")}
              >
                Move to Drafts
              </button>
            </div>
          </div>
        )}
      </div>
    );
  }
}
