import HandsInput from '../HandsInput';
import DateInput from '../DateInput';
import TextInput from '../TextInput';
import Select, {listToSelectOptions} from '../../../Fields/Select';
import Slider from '../Slider';
import BooleanSelect from '../BooleanSelect';
import DescriptionEditor from './DescriptionEditor';
import MultiSelect from '../MultiSelect';

const DESCRIPTION_MIN = 280;
const replacePriceError = (error) => _.replace(error, "price", "adoption fee");

export default function StepOne({
    name,
    price,
    breed,
    gender,
    color,
    birth_year,
    height,
    description,
    intake_date,
    microchip_serial_number,
    has_behavior_profile,
    temperament,
    temperament_short_descriptions,
    temperament_long_descriptions,
    breed_list,
    gender_list,
    color_list,
    breeds,

    errors={},
    ...props
  }) {

  const updateField = (e) => props.updateField(e.target.name, e.target.value);

  const breedOptions = listToSelectOptions(breed_list);

  if (errors['price']) errors['price'] = _.map(errors['price'], replacePriceError);
  return (
    <div>
      <TextInput
        fieldName="name"
        label="What's your horse's name?"
        required={true}
        placeholder="Type a name"
        value={name}
        updateField={updateField}
        error={errors['name']}
      />
      <TextInput
        fieldName="price"
        label="What is the adoption fee?"
        required={true}
        placeholder="0"
        value={price}
        updateField={updateField}
        error={errors['price']}
      />
      <MultiSelect
        fieldName="breeds"
        label="Choose all the breeds that fit your horse."
        required={false}
        values={breeds}
        options={breedOptions}
        updateField={props.updateField('breeds')}
        error={errors['breeds']}
      />
      <Select
        fieldName="gender"
        label="What is your horse's gender?"
        required={true}
        value={gender}
        defaultOption={['', 'Select a gender']}
        options={listToSelectOptions(gender_list)}
        updateField={updateField}
        error={errors['gender']}
      />
      <Select
        fieldName="color"
        label="What color is your horse?"
        required={true}
        value={color}
        defaultOption={['', 'Select a color']}
        options={listToSelectOptions(color_list)}
        updateField={updateField}
        error={errors['color']}
      />
      <TextInput
        fieldName="birth_year"
        label="What year was your horse born?"
        required={true}
        placeholder="Type a year"
        value={birth_year}
        updateField={updateField}
        error={errors['birth_year']}
      />
      <HandsInput
        fieldName="height"
        label="How tall is your horse?"
        required={true}
        placeholder="Type in horse height"
        value={height}
        updateField={updateField}
        error={errors['height']}
      />
      <DateInput
        fieldName="intake_date"
        label="Intake Date"
        value={intake_date}
        updateField={updateField}
        error={errors['intake_date']}
      />
      <DescriptionEditor
        label="What would you like to tell adopters about this horse?"
        required={true}
        min={DESCRIPTION_MIN}
        updateField={props.updateField('description')}
        error={errors['description']}
        value={description}
      />
      <TextInput
        fieldName="microchip_serial_number"
        label="If your horse is microchipped, what is the serial number?"
        required={false}
        placeholder="Type in serial number"
        value={microchip_serial_number}
        updateField={updateField}
        error={errors['microchip_serial_number']}
      />
      <BooleanSelect
        complete={has_behavior_profile}
        label="Has your horse completed the Basic Behavior Profile?"
        required={true}
        fieldName="has_behavior_profile"
        updateField={props.updateField}
        trueLabel="Yes"
        falseLabel="No"
      />
      <Slider
        fieldName="temperament"
        label="What is your horse's temperament?"
        value={temperament}
        valueLabels={temperament_short_descriptions}
        helpText={temperament_long_descriptions}
        updateField={updateField}
        min="0"
        max="10"
        minLabel="Calm"
        maxLabel="Spirited"
      />
    </div>
  );
}
