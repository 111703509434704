import {useState, useCallback} from 'react';
import {AsyncTypeahead} from 'react-bootstrap-typeahead';
import {Loader} from '@googlemaps/js-api-loader';

let loader;
const getLoader = (key) => {
  if (!loader) loader = new Loader({apiKey: key, "language": "en"});
  return loader;
}

export default function LocationTypeahead({selected, onChange, onInputChange, googleMapsKey}) {
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);

  const locationUpdate = useCallback((query) => {
    setLoading(true);
    getLoader(googleMapsKey).importLibrary('places').then(({AutocompleteService}) => {
      const autocomplete = new AutocompleteService();
      const queryObject = {
        input: query,
        types: ['(regions)'],
        componentRestrictions: {country: 'us'}
      };
      autocomplete.getPlacePredictions(queryObject, (predictions) => {
        setOptions(_.map(predictions, (p) => p.description.slice(0, -5)));
        setLoading(false);
      });
    });
  }, [googleMapsKey]);

  return (
    <AsyncTypeahead
      id="location"
      name="location"
      placeholder="City or Zip Code"
      isLoading={loading}
      selected={[selected]}
      options={options}
      onSearch={locationUpdate}
      onChange={([selected]) => onChange(selected)}
      onInputChange={onInputChange}
      onBlur={() => onChange(selected ? _.first(options) : null)}
      inputProps={{autoComplete: "chrome-off"}} />
  );
}
