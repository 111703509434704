import ListingCard from './ListingCard';
import EditButton from './DashboardListingCard/components/buttons/EditButton';

export default function DashboardListings({
  listings,
  openInNewTab,
  EXPIRING_SOON_DAYS,
  STATUS_REASONS,
  MAX_DASHBOARD_COUNT
}) {
  return (
    <div className="card-row">
      {_.map(listings, (listing) =>
      <div key={listing.id} className="card-column">
        <ListingCard contributor {...{listing, EXPIRING_SOON_DAYS, STATUS_REASONS, MAX_DASHBOARD_COUNT}}/>
          <div className="row">
            <EditButton listingId={listing.id} primaryClass width="full" openInNewTab={openInNewTab}/>
          </div>
        </div>
      )}
    </div>
  );
}
