import { useContext } from 'react';

import ListingContext from '@/contexts/ListingContext';
import StepContext from '@/contexts/StepContext';

import InfoStep from './InfoStep';

export default function InfoPreviewStep() {
  const {listing, unpublish} = useContext(ListingContext);
  const {step} = useContext(StepContext);

  const saveDraft = async () => {
    if (_.includes(["published", "stalled"], listing.status)) {
      await unpublish();
    }
    window.location.href = '/listings/drafted';
  };

  return (
    <InfoStep currentStep="6" step={step} title="Preview">
      <div className="text-block">
        <p>Take a look at how your listing will appear in a search. If everything looks good, make it live so someone can find their right horse!</p>
      </div>

      <button type="button" className="info-action-button" onClick={saveDraft}>Save draft</button>
    </InfoStep>
  );
}
