import { titleize } from 'components/shared/humanize';

export const DEFAULT_TERMS = {location: '', distance: 1000, organization_id: null};
export const HIDDEN_DEFAULT_TERMS = {
  breed: null,
  gender: null,
  discipline: null,
  age_range: [0, 20],
  height_range: [1, 20],
  price_range: [0, 5000]
};

const UNITS = {
  age_range: " years",
  height_range: " HH",
}

const isUnspecified = (value, key) => {
  if (_.isNil(value)) return true;
  if (key === 'distance' && value == 1000) return true;
  if (key === 'location' && value === '') return true;
  if (key === 'organization_id' && value === '') return true;
  if (_.isArray(value) && _.isEqual(_.slice(value).sort(), _.get(HIDDEN_DEFAULT_TERMS, key))) return true;
  return false;
}

export const filterUnspecifiedTerms = (terms) => _.omitBy(terms, isUnspecified);

const FilterTerm = ({term, children, value}) => {
  value = children ? [children] : _.flatten([value]);
  return <span className="text-nowrap">
    <small><i>{term}:</i></small>
    {" "}
    {_.map(value, (v) => (
      <span key={v} className="badge badge-pill badge-secondary mr-1">{v}</span>
    ))}
  </span>;
}

export const humanizedTerms = (terms, organizations) => {
  return _.compact(_.map(terms, (value, key) => {
    if (_.isEmpty(value)) return null;
    switch(key) {
      case "location":
        if (!terms["distance"]) return null;
        return (
          <FilterTerm key={key} term="Location">
            {terms["distance"]} mi <i>from</i> {value}
          </FilterTerm>
        );
      case "distance":
        return null;
      case "organization_id":
        value = parseInt(value, 10);
        const organization_name = _.find(organizations, {id: value})?.name;
        return organization_name ? <FilterTerm key={key} term="Organization" value={organization_name}/> : '';
      case "age_range":
      case "height_range":
      case "price_range":
        const max = _.last(HIDDEN_DEFAULT_TERMS[key]) === _.last(value) ? '+' : '';
        if (key === "price_range") value = _.map(value, (v) => `$${v}`);
        return (
          <FilterTerm key={key} term={titleize(key.replace('_range', ''))}>
            {_.join(value, '-')}{max}{UNITS[key] || ''}
          </FilterTerm>
        );
      case "discipline":
        return <FilterTerm key={key} term="Potential for" value={value}/>;
      default:
        return <FilterTerm key={key} term={titleize(key)} value={value}/>;
    }
  }));
};

export const similarTerms = (terms) => {
  const similar = _.reduce(filterUnspecifiedTerms(terms), (terms, value, key) => {
    switch(key) {
      case "gender":
      case "discipline":
        terms[key] = value;
        break;
      case "age_range":
      case "height_range":
      case "price_range":
        const [min, max] = HIDDEN_DEFAULT_TERMS[key];
        const low = Math.max(value[0] - (max * 0.2), min);
        const high = Math.min(value[1] + (max * 0.2), max);
        terms[key] = [low, high];
        break;
    }
    return terms;
  }, {});
  return similar
};
