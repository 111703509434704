export default class TopTen extends React.Component {
  state = {activeIndex: null}

  highlight = (data, index) => !data.hidden && this.setState({activeIndex: index})
  unhighlight = (data, index) => !data.hidden && this.setState(
    ({activeIndex}) => ({activeIndex: activeIndex == index ? null : activeIndex})
  )

  render() {
    const {data} = this.props;
    const {activeIndex} = this.state;

    return <React.Fragment>
      <div className="pie-label centered-label">
        {activeIndex != null ? data.values[activeIndex].name : 'Total'}
      </div>
      <div className="pie-value centered-label">
        {activeIndex != null ? data.values[activeIndex].value : data.total}
      </div>
      <table className="legend">
        <tbody>
          {data.values.map(({name, labelValue, color, hidden}, i) => !hidden && (
            <tr key={name} onMouseEnter={() => this.highlight({}, i)} onMouseLeave={() => this.unhighlight({}, i)}>
            <td><span className="legend-swatch" style={{background: color}}/>{name}</td>
            <td>{labelValue}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </React.Fragment>;
  }
}
