import moment from 'moment';
import { dateLabel } from '../date-helpers';
import {
  ResponsiveContainer, LineChart, CartesianGrid, XAxis, YAxis, Line,
  Tooltip, Legend
} from 'recharts';

const dateFormat = (period, startDate) => (date) => {
  let showMonth = startDate === date
  showMonth = showMonth || period === 'day' && date.match(/-01$/);
  date = moment(date);
  showMonth = showMonth || period === 'week' && date.day() == 0 && date.date() <= 7;
  showMonth = showMonth || period === 'month';
  return date.format(showMonth ? 'MMM' : 'D');
}

export default function({data: {values, series, period}, dateRange}) {
  const startDate = _.get(values, '0.date');

  return <React.Fragment>
    <ResponsiveContainer height={220}>
      <LineChart data={values} margin={{top: 0, bottom: 0}}>
        <CartesianGrid vertical={false}/>
        <XAxis dataKey="date"
          tickLine={false}
          tickFormatter={dateFormat(period, startDate)}
          minTickGap={-4}
          interval="preserveStartEnd"/>
        <YAxis mirror axisLine={false} tickLine={false} tickSize={0} allowDecimals={false} dx={-2} dy={8}/>
        <Tooltip contentStyle={{padding: "5px 10px"}}
          itemSorter={() => 1}
          labelFormatter={dateLabel(period, dateRange.to)}
          formatter={(value, name, props) => (
            <div><span className="legend-swatch" style={{background: props.color}}/>{value} {name}</div>
          )}/>
        <Legend align="left" verticalAlign="top" iconType="circle" iconSize={10} height={28} wrapperStyle={{fontSize: 12}}/>
        {series.map(({key, color}) => (
          <Line key={key} dataKey={key} dot={false} stroke={color} activeDot={{r: 7, strokeWidth: 0}}/>
        ))}
      </LineChart>
    </ResponsiveContainer>
  </React.Fragment>;
}
