export default function EditButton({listingId, primaryClass, width="half", openInNewTab}) {
  return (
    <div className={width == "half" ? "col-6" : "col-12"}>
      <a
        className={primaryClass == true ? "btn btn-block btn-outline-secondary" : "btn btn-block btn-secondary"}
        href={`/listings/${listingId}/edit`}
        role="button"
        target={openInNewTab ? '_blank' : null}
      >
        Edit
      </a>
    </div>
  );
}
