import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

export function listToSelectOptions(list) {
  return _.map(list, (item) => [item, item]);
}

export default class Select extends React.Component {
  constructor(props) {
    super(props);
    this.state = {dropdownOpen: false}
  }

  updateField = (e) => {
    if (e.target.value === "") {
      this.props.updateField({target: {
        name: e.target.name,
        value: null
      }});
    } else {
      this.props.updateField(e);
    }
  }

  toggleSelect = () => this.setState(({dropdownOpen}) => ({dropdownOpen: !dropdownOpen}));

  humanizedValue = (value, options) => {
    if (isNaN(value) || value == "") {
      return value;
    } else {
      const option = _.find(options, (o) => o[0] == value) || [null, ""];
      return option[1];
    }
  }

  render() {
    const {label, value, required, fieldName, defaultOption, options, error=[]} = this.props;
    const requiredField = required ? '*' : null;
    const defaultValue = defaultOption ? defaultOption[1] : null;

    return (
      <div className={classnames("form-group", {error: error.length})}>
        <label>{label} {requiredField}</label>
        {error.length > 0 && <div className="error-message">{ _.capitalize(error[0]) }</div>}

        <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggleSelect}>
          <DropdownToggle
            name={fieldName}
            className={classnames("btn", "btn-outline-primary", "custom-select", {error: error.length})}
            caret
          >
            {(_.isNil(value) || value === defaultOption[0]) ? defaultValue : this.humanizedValue(value, [defaultOption, ...options])}
          </DropdownToggle>
          <DropdownMenu className="scrollable-menu">
            {defaultOption[0] === "" ? null :
              <DropdownItem
                key={defaultOption[0]}
                name={fieldName}
                value={defaultOption[0]}
                onClick={e => this.updateField(e)}
              >
                {defaultOption[1]}
              </DropdownItem>
            }
            { _.map(options, ([value, display]) =>
              <DropdownItem
                key={value}
                name={fieldName}
                value={value}
                onClick={e => this.updateField(e)}
              >
                {display}
              </DropdownItem>
            )}
          </DropdownMenu>
        </Dropdown>
      </div>
    )
  }
}
