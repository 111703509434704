const ANIMATION_DURATION = 1500;

const createFrame = () => {
  const iframe = document.createElement('iframe');
  iframe.style = `
    width: 1140px;
    display: block;
    position: absolute;
    left: 100vw;
    top: 0;
  `;
  const src = new URL(window.location.href);
  src.hash = 'report';
  iframe.src = src;
  return iframe;
};

export const loadFrame = (state) => {
  const iframe = createFrame();
  return new Promise((resolve) => {
    const returnMarkup = (e) => {
      if (e.data.type === 'loaded') {
        window.removeEventListener("message", returnMarkup);
        setTimeout(() => {
          resolve(iframe.contentWindow.document.body.outerHTML);
          document.body.removeChild(iframe);
        }, ANIMATION_DURATION);
      }
    };
    iframe.onload = () => {
      iframe.contentWindow.document.body.classList.add("small-margins");
      window.addEventListener("message", returnMarkup);
      iframe.contentWindow.postMessage(state, window.location.href);
    };
    document.body.appendChild(iframe);
  });
};
