import moment from 'moment';
import DayPicker, { DateUtils } from 'react-day-picker';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

const MONTH_LABELS = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
const WEEKDAY_LABELS = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];

const dateFormat = (date) => moment(date).format('YYYY-M-D');
const toDate = (str) => moment(str).toDate();

const today = () => new Date();
const ago = (n, unit) => () => moment().subtract(n, unit).toDate();
const beginningOfYear = () => moment().startOf('year').toDate();
const beginningOfLastYear = () => moment().subtract(1, 'year').startOf('year').toDate();
const endOfLastYear = () => moment().subtract(1, 'year').endOf('year').toDate();

const DATE_RANGE_OPTIONS = [
  {name: 'Active Listings', start: ago(30, 'days'), end: today, active: true},
  {name: '30 Days', start: ago(30, 'days'), end: today},
  {name: '90 Days', start: ago(90, 'days'), end: today},
  {name: '6 Months', start: ago(6, 'months'), end: today},
  {name: '12 Months', start: ago(12, 'months'), end: today},
  {name: 'Year to Date', start: beginningOfYear, end: today},
  {name: 'Previous Year', start: beginningOfLastYear, end: endOfLastYear},
];

const findRangeName = ({from, to}, activeOnly) => _.find(DATE_RANGE_OPTIONS, ({start, end, active=false}) => (
  moment(from).isSame(start(), 'day') && moment(to).isSame(end(), 'day') && activeOnly == active
)) || {name: 'Custom'};

export default class DateRangePicker extends React.Component {
  constructor(props) {
    super(props);
    const {from, to} = props.dateRange;
    const {name} = findRangeName(props.dateRange, props.activeOnly);
    this.state = {
      showPicker: false,
      selectedRange: {name, from: toDate(from), to: toDate(to)},
      dropdownOpen: false
    }
    this.debouncedLoadPage = _.debounce(this.loadPage, 1000);
  }

  loadPage = ({from, to}, name) => {
    if (!moment(from).isValid() || !moment(to).isValid()) return;
    this.props.onDateRangeChange({from, to}, name);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.closeDayPicker, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.closeDayPicker, false);
  }

  toggleDateRangeSelect = () => this.setState(({dropdownOpen}) => ({dropdownOpen: !dropdownOpen}))

  closeDayPicker = (e) => {
    if (this.node.contains(e.target)) return;
    this.setState({showPicker: false});
  }
  toggleDayPicker = () => this.setState(({showPicker}) => ({showPicker: !showPicker}))

  setRange = (name) => {
    const {start, end} = _.find(DATE_RANGE_OPTIONS, {name});
    const range = {from: start(), to: end(), name};
    this.setState({selectedRange: {from: start(), to: end(), name}});
    this.loadPage(range, name);
  }

  updateSelectedRange = (day) => {
    const range = DateUtils.addDayToRange(day, this.state.selectedRange);
    if (range.from !== null && range.to !== null) {
      this.setState({selectedRange: {...range, name: 'Custom'}});
      this.debouncedLoadPage(range);
    }
  }

  render() {
    const {showPicker, selectedRange: {from, to, name}} = this.state;
    const {loading, activeOnly, iframe} = this.props;
    const displayName = (iframe && name == 'Custom') ? 'Custom Date Range' : name;

    return (
      <div className="daterange-picker" ref={(node) => this.node = node}>
        <div
          className={classnames("btn", "btn-outline-primary", "datepicker-select", {disabled: activeOnly})}
          onClick={activeOnly ? _.noop() : this.toggleDayPicker}
        >
          <i className={loading ? "icon-spin4 animate-spin" : "icon-calendar"} />{' '}
          {moment(from).format('MMM D, YYYY')} - {moment(to).format('MMM D, YYYY')}
          {showPicker && (
            <div className={classnames("datepicker-dropdown")} onClick={(e) => e.stopPropagation()}>
              <DayPicker
                numberOfMonths={2}
                showOutsideDays={true}
                fixedWeeks={true}
                months={MONTH_LABELS}
                weekdaysShort={WEEKDAY_LABELS}
                initialMonth={moment(to).subtract(1, 'month').toDate()}
                selectedDays={[from, { from, to }]}
                modifiers={{start: from, end: to}}
                onDayClick={this.updateSelectedRange}
              />
            </div>
          )}
        </div>
        <Dropdown
          className="daterange-select-wrapper"
          isOpen={this.state.dropdownOpen}
          toggle={this.toggleDateRangeSelect}
        >
          <DropdownToggle className="btn btn-outline-primary daterange-select" caret>
            <p className="dropdown-button-value">{activeOnly ? "Active Listings" : displayName}</p>
          </DropdownToggle>
          <DropdownMenu right>
            {name == 'Custom' && <DropdownItem>Custom</DropdownItem>}
            {DATE_RANGE_OPTIONS.map(({name, ...range}) => (
              <DropdownItem key={name} onClick={e => this.setRange(name)}>{name}</DropdownItem>
            ))}
          </DropdownMenu>
        </Dropdown>
      </div>
    );
  }
}
