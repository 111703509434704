import InfoStep from './InfoStep';

export default function InfoStepThree() {
  return (
    <InfoStep currentStep="3" title="Video & Photos">
      <div className="text-block">
        <p>The best videos are just a few minutes long, in good lighting, without heavy editing or loud music.</p>
      </div>
      <div className="text-block">
        <p>The most important photo is the conformation shot from the side of the horse. Photos of the front, rear legs, and a photo of the horse engaging in its discipline are helpful too.</p>
      </div>
      <div className="text-block">
        <p>Image file types that can be uploaded here include JPEG, JPG, and PNG.</p>
      </div>
    </InfoStep>
  );
}
