export default class HandsInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = { value: this.props.value };
  }

  translateToHands = ({target: {value}}) => {
    this.setState({value: value % 1 >= 0.4 ? _.ceil(value) : value});
  }

  render() {
    const {label, required, placeholder, updateField, fieldName, error=[], has_errors} = this.props;
    const {value} = this.state;
    const requiredField = required ? '*' : null;
    return (
      <div className={classnames("form-group", {error: error.length})}>
        <label>{label} {requiredField}</label>
        <div className={classnames('error-message', {has_errors})}>
          { _.capitalize(error.join(', ')).replace(/_/g, ' ') }
        </div>
        <input type="number"
               name={fieldName}
               value={value ? value : ''}
               className="form-control"
               placeholder={placeholder}
               onChange={this.translateToHands}
               onBlur={updateField}/>
      </div>
    )
  }
}
