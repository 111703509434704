import Select from '../../../Fields/Select';
import MultiSelect, { listToSelectOptions } from '../MultiSelect';

function collectionToSelectOptions(coll, valueKey='id', displayKey='name') {
  return _.map(coll, (item) => [item[valueKey], item[displayKey]]);
}

export default class StepTwo extends React.Component {
  render() {
    const {
      primary_location={},
      location_id,
      locations,
      disciplines,
      discipline_list,
      errors={}
    } = this.props;

    const updateField = (e) => {
      this.props.updateField(e.target.name, e.target.value);
    };

    const defaultLocationOption = [primary_location.id, primary_location.name]
    const otherLocations = _.reject(locations, {id: primary_location.id||''});
    const locationOptions = collectionToSelectOptions(otherLocations);

    const disciplineOptions = listToSelectOptions(discipline_list);

    return (
      <div>
        <Select
          fieldName="location_id"
          label="Select the location where your horse can be found."
          required={false}
          value={location_id}
          defaultOption={defaultLocationOption}
          options={locationOptions}
          updateField={updateField}
          error={errors['location_id']}
        />
        <MultiSelect
          fieldName="disciplines"
          label="Choose all the disciplines that fit your horse."
          required={false}
          values={disciplines}
          options={disciplineOptions}
          updateField={this.props.updateField('disciplines')}
          error={errors['disciplines']}
        />
      </div>
    );
  }
}
