import { set } from 'lodash/fp';
import { useState } from 'react';
import { saveAs } from 'file-saver';
import { titleize } from 'components/shared/humanize';
import { exportCSV } from 'components/shared/listing_helpers';

const SCOPES = ['active', 'expiring_soon', 'expired', 'drafted', 'stalled', 'adopted', 'archived'];
const DEFAULT_FILTERS = {
  created_at: {start: '', end: ''},
  updated_at: {start: '', end: ''},
  scope: SCOPES
};
const filtersFromLocation = ({search}) => {
  if (search) {
    const params = new URLSearchParams(search);
    return {
      ...DEFAULT_FILTERS,
      scope: params.getAll('scope')
    };
  }
}

const regExpFilename = /filename="(?<filename>.*)"/;

const defaultMapping = (fields) => _.reduce(fields, (mapping, field) => ({
  ...mapping, [field]: field
}), {});

const MappingRow = ({field, value, setValue}) => (
  <tr>
    <td>{field}</td>
    <td className="py-1">
      <input value={value} onChange={(e) => setValue(e.target.value)} className="form-control"/>
    </td>
  </tr>
);

export default function CSVExportForm({availableFields, previousMapping, data=[]}) {
  const [filters, setFilters] = useState(filtersFromLocation(location) || DEFAULT_FILTERS);
  const [mapping, setMapping] = useState(previousMapping || defaultMapping(availableFields));
  const [previewIndex, setPreviewIndex] = useState(0);
  const [error, setError] = useState();

  const toggleStatus = (scope) => ({target: {checked}}) => setFilters({
    ...filters, scope: checked ? _.uniq([...filters.scope, scope]) : _.without(filters.scope, scope)
  });
  const download = async () => {
    setError();
    try {
      const {headers, data} = await exportCSV(_.omitBy(mapping, _.isEmpty), filters);
      const {filename} = regExpFilename.exec(headers['content-disposition'])?.groups ?? {};
      saveAs(new Blob([data]), filename);
      if (window.navigation?.canGoBack) {
        window.history.back();
      } else {
        window.location.href = '/dashboard';
      }
    } catch (e) {
      setError(e.message);
    }
  };

  return (
    <div className="my-2">
      {error && <div className="alert alert-danger" role="alert">{error}</div>}

      <div className="form-inline">
        <label className="form-group mr-4">
          Created At:
          <input
            name="created_at_start"
            type="date"
            className="form-control mx-1"
            value={filters.created_at.start}
            onChange={({target: {value}}) => setFilters(set('created_at.start', value, filters))}/>
          to
          <input
            name="created_at_end"
            type="date"
            className="form-control mx-1"
            value={filters.created_at.end}
            onChange={({target: {value}}) => setFilters(set('created_at.end', value, filters))}/>
        </label>

        <label className="form-group">
          Updated At:
          <input
            name="updated_at_start"
            type="date"
            className="form-control mx-1"
            value={filters.updated_at.start}
            onChange={({target: {value}}) => setFilters(set('updated_at.start', value, filters))}/>
          to
          <input
            name="updated_at_end"
            type="date"
            className="form-control mx-1"
            value={filters.updated_at.end}
            onChange={({target: {value}}) => setFilters(set('updated_at.end', value, filters))}/>
        </label>
      </div>

      <div className="form-inline">
        <label className="my-2 mr-2">Include:</label>
        <div className="form-group">
          {SCOPES.map((scope) => (
            <label key={scope} className="form-check form-check-inline">
              <input type="checkbox"
                className="form-check-input"
                checked={_.includes(filters['scope'], scope)}
                onChange={toggleStatus(scope)}/>
              {titleize(scope)}
            </label>
          ))}
        </div>
      </div>

      <table className="table">
        <thead>
          <tr>
            <th className="text-nowrap">Right Horse Field</th>
            <th className="text-nowrap" style={{minWidth: '150px'}}>CSV Field</th>
          </tr>
        </thead>
        <tbody>
          {availableFields.map((field) => (
            <MappingRow key={field}
              field={field}
              previewRow={data[previewIndex]}
              value={mapping[field]}
              setValue={(value) => setMapping({...mapping, [field]: value})}/>
          ))}
        </tbody>
      </table>

      <p className="text-center py-4">
        <button className="btn btn-primary btn-lg" onClick={download}>
          Download CSV
        </button>
      </p>
    </div>
  );
}
