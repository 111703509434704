import ReactSlider from 'react-slider';

export default function Slider(props) {
  const {name, min, max, step, value, before, after, setTerm, setTermLabel, disabled} = props;

  const format = (value) => {
    const overflow = value == max ? '+' : '';
    return [before, value, overflow, after].join('');
  };
  const fakeEvent = (value) => ({target: {name, value}});

  return (
    <div className="slider-field">
      <div className="value">
        {_.isArray(value) ? `${format(value[0])} to ${format(value[1])}` : format(value)}
      </div>
      <ReactSlider
        className={classnames("slider", {"range": _.isArray(value), hasValue: !_.isNil(value)})}
        name={name}
        value={value}
        withTracks
        min={min}
        max={max}
        step={step || 1}
        disabled={disabled}
        onAfterChange={(newValue) => setTerm(fakeEvent(newValue))}
        onChange={(newValue) => setTermLabel(fakeEvent(newValue))} />
    </div>
  );
}
