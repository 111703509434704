export default function headers() {
  const crsfToken = _.get(document.querySelectorAll('meta[name="csrf-token"]'), '[0].content');
  return (
    {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': crsfToken
    }
  )  
}
