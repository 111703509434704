import ListingCard from '../../../ListingCard';

export default function PreviewStep(props) {
  const {locations, location_id, userType, userID} = props;
  const location = _.find(locations, { id: location_id });
  return <ListingCard
    listing={{...props, location, status: 'published', status_reason: null}}
    userType={userType}
    userID={userID}/>;
}
