import {
  ResponsiveContainer,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Bar
} from 'recharts';

export default function({data}) {
  const { dataColor, dataKey, values} = data;

  return <React.Fragment>
    <ResponsiveContainer height={280}>
      <BarChart data={values} margin={{top: 7, bottom: 10}}>
        <CartesianGrid vertical={false} horizontalPoints={[1, 60, 120, 180]}/>
        <XAxis
          dataKey="name"
          type="category"
          textAnchor="middle"
          tick={{angle: 0}}
          fontSize={10}
          interval={0}
          padding={{left:20, right: 15}}
        />
        <YAxis
          mirror
          allowDecimals={false}
          axisLine={false}
          tickLine={false}
          tickSize={0}
          domain={[0, 100]}
          tickFormatter={(tick) => tick + '%'}
        />
        <Tooltip
          contentStyle={{padding: "5px 10px"}}
          labelFormatter={(ageRange) => ageRange + ' years'}
          formatter={(value, name) => <div>{name} {value}%</div>}
          cursor={false}
        />
        <Bar dataKey={dataKey} dot={false} fill={dataColor} barSize={10}/>
      </BarChart>
    </ResponsiveContainer>
    <dl className="graph-total">
      <dt>Age</dt>
    </dl>
  </React.Fragment>;
}
