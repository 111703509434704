import ReversibleButton from './buttons/ReversibleButton';
import EditButton from './buttons/EditButton';
import ChangeStatusButton from './buttons/ChangeStatusButton';
import AdoptedButton from './buttons/AdoptedButton';

export default function DraftButtons({
  submitListingAction, initialStatus, listing, moved, statusReasons
}) {
  const pendingAdoption = listing.status_reason === "Pending Adoption";

  return (
    <div>
      <div className="row">
        <ReversibleButton
          primaryClass
          activeStatus={listing.status}
          newStatus="published"
          action={submitListingAction}
          actionLabel="Publish"
          moved={moved}
          width={pendingAdoption ? "half" : "full"}/>
        {pendingAdoption && (
          <AdoptedButton
            primaryClass
            listing={listing}
            action={submitListingAction}
            moved={moved}
            statusReasons={statusReasons}/>
        )}
      </div>
      <div className="row">
        <EditButton primaryClass listingId={listing.id}/>
        <ChangeStatusButton
          primaryClass
          dropupMenu
          listing={listing}
          action={submitListingAction}
          initialStatus={initialStatus}
          moved={moved}
          statusReasons={statusReasons}/>
      </div>
    </div>
  )
}
